import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import {
  Box,
  Collapse,
  CSSObject,
  Drawer,
  Fade,
  List,
  ListSubheader,
  styled,
  Theme,
  Typography,
} from '@mui/material'
import cookies from 'js-cookie'
import React, { useEffect } from 'react'

import { Organization, User } from '../../../../../graphql/types'
import { track } from '../../../../main/coderpad_analytics'
import { useAppStateProperty } from '../../AppState/AppStateProvider'
import * as ListItems from './ListItems'

const openedMixin = (theme: Theme): CSSObject => ({
  '&, & .MuiDrawer-paper': {
    width: 270,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
})

const closedMixin = (theme: Theme): CSSObject => ({
  '&, & .MuiDrawer-paper': {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const NavDrawer = styled(Drawer)(({ theme, open }) => ({
  width: 270,
  flexShrink: 0,
  position: 'sticky',
  top: 78,
  '& .MuiDrawer-paper': {
    whiteSpace: 'nowrap',
    backgroundColor: '#E2E6EA',
    position: 'sticky',
    border: 'none',
    top: 78,
  },
  ...(open ? openedMixin(theme) : closedMixin(theme)),
}))

const OpenerIcon = styled(MenuOpenIcon, {
  shouldForwardProp: (prop) => prop !== 'flip',
})<{ flip: boolean }>(({ theme, flip }) => ({
  cursor: 'pointer',
  color: theme.palette.grey[500],
  width: 20,
  height: 20,
  transform: flip ? 'scaleX(-1)' : 'scaleX(1)',
  transition: `${theme.transitions.duration.leavingScreen} ease-in-out`,
}))

interface IFullNavProps {
  organization?: Organization
  currentUser: User
  noRouter?: boolean
}

export const FullNav: React.FC<IFullNavProps> = ({ currentUser, organization, noRouter }) => {
  const { value: isOpen, set: setIsOpen } = useAppStateProperty('appNavOpen')

  const hasAdminPrivs = currentUser.organizationOwner || !organization
  const isCodingameXSell = currentUser.activeQuota?.planObj?.codingameXsellPlan ?? false

  useEffect(() => {
    cookies.set('app_nav_state', isOpen ? '' : '1')
  }, [isOpen])

  const toggleIsOpen = () => {
    track(`sidenav menu ${isOpen ? 'collapsed' : 'expanded'}`, { version: 'original' })
    setIsOpen(!isOpen)
  }

  // The main nav section.
  return (
    <NavDrawer open={isOpen} variant="permanent">
      <Collapse in={!isOpen}>
        <Box display="flex" justifyContent="flex-end" pr={3} mb={0.5}>
          <OpenerIcon onClick={toggleIsOpen} flip={!isOpen} />
        </Box>
      </Collapse>
      <List component="nav" data-testid="sidenav">
        <Box position="absolute" right={16} top={14} zIndex={1}>
          <Fade in={isOpen} timeout={{ enter: 500, exit: 0 }} unmountOnExit={true}>
            <OpenerIcon onClick={toggleIsOpen} flip={!isOpen} />
          </Fade>
        </Box>
        {organization ? (
          <Collapse in={isOpen}>
            <ListSubheader disableSticky={true} sx={{ pt: 1, pb: 2, mr: 3 }}>
              <Typography
                variant="h3"
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                color="textPrimary"
              >
                {organization.name}
              </Typography>
            </ListSubheader>
          </Collapse>
        ) : null}
        <ListItems.PadList noRouter={noRouter} />
        {/* No org or org questions enabled gates the Question Bank link */}
        {!organization || organization?.questionsEnabled ? (
          <ListItems.QuestionBank noRouter={noRouter} />
        ) : null}
        <ListItems.Files noRouter={noRouter} />
        <ListItems.Databases noRouter={noRouter} />
        {organization && (hasAdminPrivs || (organization?.padIndexEnabled && !isCodingameXSell)) ? (
          <ListItems.Users noRouter={noRouter} hasOrganization={true} />
        ) : !organization ? (
          <ListItems.Users noRouter={true} hasOrganization={false} />
        ) : null}
        {currentUser.allowSandboxAccess ? <ListItems.Sandbox /> : null}
      </List>
    </NavDrawer>
  )
}
