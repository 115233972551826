import { Chip, ChipProps, styled } from '@mui/material'
import React from 'react'

const StyledChip = styled(Chip)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  borderRadius: theme.spacing(1),
  border: '1px solid' + theme.palette.grey[50],
  backgroundColor: theme.palette.background.default,
  fontSize: '0.75rem',
  lineHeight: '0.75rem',
  height: theme.spacing(3),
  '& .MuiChip-icon': {
    height: theme.spacing(2),
    width: theme.spacing(2),
    margin: theme.spacing(0, 1, 0, 0),
  },
  '& .MuiChip-label': {
    padding: 0,
  },
  '& .MuiChip-deleteIcon': {
    position: 'relative',
    left: theme.spacing(1.5),
  },
}))

export const CpChip: React.FC<ChipProps> = ({ ...props }) => {
  return <StyledChip {...props} />
}
