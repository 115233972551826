import { Box, InputAdornment, TextField, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import path from 'path-browserify'
import React, {
  ChangeEventHandler,
  FormEventHandler,
  KeyboardEventHandler,
  useCallback,
  useState,
} from 'react'

import {
  DirectoryItemNameValidity,
  DirectoryItemNameValidityLevel,
  DirectoryItemNameValidityType,
  getDirectoryItemNameValidity,
} from './utils/directoryItemName'

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    color: theme.palette.filePane?.inputText,
    '& > .MuiInputBase-root': {
      backgroundColor: theme.palette.filePane?.inputBackground,
    },
  },
  inputAdornment: {
    '& path': {
      stroke: theme.palette.filePane?.inputText,
    },
  },
}))

interface IDirectoryItemProps {
  defaultValue?: string
  icon: React.ReactNode
  onExit: () => void
  onSubmit: (value: string) => void
}

export const DirectoryItemInput: React.FC<IDirectoryItemProps> = ({
  defaultValue,
  icon,
  onExit,
  onSubmit,
}) => {
  const styles = useStyles()
  const [
    { level: validityLevel, label: validityLabel },
    setNameValidity,
  ] = useState<DirectoryItemNameValidity>({
    type: DirectoryItemNameValidityType.Valid,
    level: DirectoryItemNameValidityLevel.Ok,
    label: '',
  })

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setNameValidity(getDirectoryItemNameValidity(event.target.value))
  }, [])

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === 'Escape') {
        onExit()
      }
    },
    [onExit]
  )

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault()

      if (validityLevel !== DirectoryItemNameValidityLevel.Error) {
        const value = new FormData(event.currentTarget).get('itemName')! as string
        // Normalization removes multiple, sequential slashes
        onSubmit(path.normalize(value))
      }
    },
    [onSubmit, validityLevel]
  )

  const focusCallback = useCallback((inputElement) => {
    if (inputElement != null) {
      // the autoFocus prop won't work for us, since a blur event is triggered
      // when the contextMenu's presentation div is closed.  Instead, we'll use
      // a setTimeout to ensure the input is mounted and the presentation div is
      // closed before we focus the input
      setTimeout(() => {
        inputElement.focus()
      }, 1)
    }
  }, [])

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <TextField
        error={validityLevel === DirectoryItemNameValidityLevel.Error}
        helperText={validityLevel !== DirectoryItemNameValidityLevel.Ok ? validityLabel : undefined}
        defaultValue={defaultValue}
        variant="standard"
        inputRef={focusCallback}
        onBlur={onExit}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className={styles.textField}
        name="itemName"
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start" className={styles.inputAdornment}>
              {icon}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}
