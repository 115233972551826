import { Divider } from '@mui/material'
import { uniqBy } from 'lodash'
import React from 'react'

import { BorderColorControl } from './BorderColorControl'
import { FillColorControl } from './FillColorControl'
import { FontAlignmentControl } from './FontAlignmentControl'
import { FontColorControl } from './FontColorControl'
import { FontControl } from './FontControl'
import { FontSizeControl } from './FontSizeControl'
import { FontStyleControl } from './FontStyleControl'
import { LineColorControl } from './LineColorControl'
import { LineWidthControl } from './LineWidthControl'
import { MoveSelectionBackwardControl } from './MoveSelectionBackwardControl'
import { MoveSelectionForwardControl } from './MoveSelectionForwardControl'

const layeringControls = [
  <MoveSelectionBackwardControl key="moveDown" />,
  <MoveSelectionForwardControl key="moveUp" />,
]

const shapeControls = [
  <BorderColorControl key="borderColor" />,
  <FillColorControl key="fillColor" />,
]

const textControls = [
  <FontControl key="font" />,
  <Divider key="divider1" orientation="vertical" />,
  <FontSizeControl key="fontSize" />,
  <FontStyleControl key="fontStyle" />,
  <FontAlignmentControl key="fontAlign" />,
  <FontColorControl key="fontColor" />,
]

const strokeControls = [<LineWidthControl key="lineWidth" />, <LineColorControl key="lineColor" />]

const SingleControlGroups = {
  shape: [...layeringControls, ...shapeControls],
  text: textControls,
  stroke: strokeControls,
}

const MultiControlGroups = {
  shape: shapeControls,
  text: textControls,
  stroke: strokeControls,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getControlGroupForSelection = (selection: any) => {
  if (selection.type === 'activeselection') {
    const objects = selection.getObjects()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const uniqueTypes = uniqBy(objects, (o: any) => o.type).length
    if (uniqueTypes === 1) {
      const sampleObject = objects[0]
      const group = getObjectControlType(sampleObject)
      if (group) {
        return MultiControlGroups[group]
      }
    }
  } else {
    const group = getObjectControlType(selection)
    if (group) {
      return SingleControlGroups[group]
    }
  }

  return undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getObjectControlType = (object: any) => {
  if (object.isShapeContainer) {
    return 'shape'
  } else if (object.type === 'i-text' || object.type === 'textbox') {
    return 'text'
  } else if (object.isLineContainer || object.type === 'path' || object.type === 'line') {
    return 'stroke'
  }
  return undefined
}
