import { Button } from '@mui/material'
import React from 'react'
import { FileWithPath } from 'react-dropzone'

import { PrettyMaxFileSize } from '../../../FolderDropZone/config'
import { FileMessageQueueItem } from '../FileMessageDialog'

export interface FileTooLargeDialogProps {
  file: FileWithPath
  onOk: () => void
}

export const FileTooLargeDialog = ({
  file,
  onOk,
}: FileTooLargeDialogProps): FileMessageQueueItem => ({
  title: 'File is too large',
  message: (
    <>
      The file <b>{file.name}</b> exceeds the file size limit of {PrettyMaxFileSize}. Please reduce
      the file size and try again.
    </>
  ),
  actions: [
    <Button key={1} color="secondary" onClick={onOk} variant="outlined">
      OK
    </Button>,
  ],
})
