import { WhiteboardEngine } from '@codinpad/wbengine-client'
import * as Sentry from '@sentry/browser'

export interface MessagedError {
  message: string
}

export interface IWhiteboardProps {
  allowInit?: boolean
  darkColorScheme?: boolean
  drawingBoardId?: string
  authorId?: string
  isSandbox?: boolean
  sandboxDrawingBoardId?: string | null
  slug?: string
}

export interface ToolOptions {
  shape: {
    stroke: string
    fill: string
    fillOpacity: number | null
  }
  text: {
    stroke: string
    fill: string
    fontFamily: string
    fontSize: number
    fontStyle: {
      fontWeight: string
      fontStyle: string
      isUnderlined: boolean
      isStrikethrough: boolean
    }
  }
  line: {
    stroke: string
    strokeWidth: number
  }
}

interface IInitWBEngineProps {
  sandbox: boolean
  canvasElement: HTMLCanvasElement
  setIsConnected: (isConnected: boolean) => void
}

export const initWBEngine = ({ sandbox, canvasElement, setIsConnected }: IInitWBEngineProps) => {
  const wb = new WhiteboardEngine({
    canvasElement,
    userPointers,
    socket: {
      host: window.CoderPad.DRAWING_URL,
      transports: ['websocket', 'polling'],
    },
    sandbox,
  })

  wb.on('connect', () => {
    setIsConnected(true)
    // Trigger a resize after connecting to make sure the canvas fits its container.
    wb.resize()
  })
  wb.on('disconnect', () => setIsConnected(false))
  wb.on('connect_error', () => {
    setIsConnected(false)
    Sentry.captureMessage('Drawing connection error', {
      level: 'error' as Sentry.SeverityLevel,
      tags: { layer: 'react', feature: 'drawing_mode' },
    })
  })

  return wb
}

export const seedObjectsToSandboxBoard = async (
  wbEngine: WhiteboardEngine,
  sandboxDrawingBoardId: string
) => {
  try {
    const response = await fetch(`/drawing_objects?board_id=${sandboxDrawingBoardId}`, {
      method: 'get',
    })
    if (!response.ok) throw new Error(`Failed to fetch objects. Status: ${response.status}`)

    const boardObjects = await response.json()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const objects = boardObjects.map((obj: any) => ({ ...obj.data }))
    wbEngine?.addObjects(objects)
  } catch (error) {
    console.error('Error while fetching objects:', error)
  }
}

export const userPointers = {
  // @ts-ignore
  create(userData) {
    /* Note: el will be positioned absolutely within the canvas' parent
     * element, we'll use opacity to show/hide, and "left" / "top"
     * will be set to correct position */
    const el = document.createElement('div')
    el.style.transition = 'all 0.25s ease-in-out'
    el.style.padding = '3px 5px 3px 5px'
    el.style.borderRadius = '5px'
    el.style.color = '#fff'
    return el
  },

  // @ts-ignore
  update(el, userData) {
    el.innerText = userData.name || '(anonymous)'
    // el.style.backgroundColor = userData.color || _getUserColor(userData.userId)
    el.style.backgroundColor = userData.color || 'blue'
  },

  // @ts-ignore
  dispose(el, userId) {
    // No special cleanup we need to do here, in this case doesn't
    // even need specifying
  },
}

export function toolToWBMode(toolName: string, defaultToolOptions: ToolOptions) {
  let mode = ''
  let toolOptions = {}

  const shapeAndFontOptions = {
    strokeWidth: 2,
    stroke: defaultToolOptions.shape.stroke,
    fill: defaultToolOptions.shape.fill,
    fillColor: defaultToolOptions.shape.fill,
    fillOpacity: defaultToolOptions.shape.fillOpacity,
    fontFamily: defaultToolOptions.text.fontFamily,
    fontColor: defaultToolOptions.text.stroke,
    fontSize: defaultToolOptions.text.fontSize,
    fontWeight: defaultToolOptions.text.fontStyle.fontWeight,
    fontStyle: defaultToolOptions.text.fontStyle.fontStyle,
    underline: defaultToolOptions.text.fontStyle.isUnderlined,
    linethrough: defaultToolOptions.text.fontStyle.isStrikethrough,
  }

  switch (toolName) {
    case 'select': {
      mode = 'select'
      break
    }
    case 'pencil': {
      mode = 'free'
      toolOptions = {
        strokeWidth: defaultToolOptions.line.strokeWidth,
        stroke: defaultToolOptions.line.stroke,
        startArrow: false,
        endArrow: false,
      }
      break
    }
    case 'marker': {
      mode = 'free'
      toolOptions = {
        strokeWidth: 10,
        stroke: defaultToolOptions.shape.stroke,
        startArrow: false,
        endArrow: false,
      }
      break
    }
    case 'text': {
      mode = 'tool:text'
      toolOptions = {
        stroke: defaultToolOptions.text.stroke,
        fill: defaultToolOptions.text.stroke,
        fontFamily: defaultToolOptions.text.fontFamily,
        fontSize: defaultToolOptions.text.fontSize,
        fontWeight: defaultToolOptions.text.fontStyle.fontWeight,
        fontStyle: defaultToolOptions.text.fontStyle.fontStyle,
        underline: defaultToolOptions.text.fontStyle.isUnderlined,
        linethrough: defaultToolOptions.text.fontStyle.isStrikethrough,
      }
      break
    }
    case 'lineStraight': {
      mode = 'tool:line'
      toolOptions = {
        strokeWidth: defaultToolOptions.line.strokeWidth,
        stroke: defaultToolOptions.line.stroke,
        startArrow: false,
        endArrow: false,
      }
      break
    }
    case 'lineArrow': {
      mode = 'tool:line'
      toolOptions = {
        strokeWidth: defaultToolOptions.line.strokeWidth,
        stroke: defaultToolOptions.line.stroke,
        startArrow: false,
        endArrow: true,
      }
      break
    }
    case 'lineDoubleArrow': {
      mode = 'tool:line'
      toolOptions = {
        strokeWidth: defaultToolOptions.line.strokeWidth,
        stroke: defaultToolOptions.line.stroke,
        startArrow: true,
        endArrow: true,
      }
      break
    }
    case 'arrow': {
      mode = 'free'
      toolOptions = {
        strokeWidth: defaultToolOptions.line.strokeWidth,
        stroke: defaultToolOptions.line.stroke,
        startArrow: false,
        endArrow: true,
      }
      break
    }
    case 'rectangle': {
      mode = 'tool:rect'
      toolOptions = { ...shapeAndFontOptions }
      break
    }
    case 'ellipse': {
      mode = 'tool:ellipse'
      toolOptions = { ...shapeAndFontOptions }
      break
    }
    case 'diamond': {
      mode = 'tool:diamond'
      toolOptions = { ...shapeAndFontOptions }
      break
    }
    case 'cylinder': {
      mode = 'tool:cylinder'
      toolOptions = { ...shapeAndFontOptions }
      break
    }
    case 'eraser': {
      mode = 'tool:eraser'
      break
    }
    case 'objectDeleter': {
      mode = 'tool:objectDeleter'
      break
    }
    case 'pan': {
      mode = 'pan'
    }
  }

  return { mode, toolOptions }
}
