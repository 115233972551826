import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import * as queryStates from '../../queryStates'
import { Language, ProjectTemplate } from '../../types'
import { UseLanguagesData } from '../languages/useLanguages'
import { IProjectTemplatesData } from '../useProjectTemplates/useProjectTemplates'

export const PROJECT_TEMPLATES_QUERY = gql`
  query ProjectTemplates($padSlug: String) {
    projectTemplates(padSlug: $padSlug) {
      name
      slug
    }
  }
`

export const LANG_QUERY = gql`
  query Languages {
    languages {
      id
      name
    }
  }
`

export type LanguageAndProjectTemplateListItem = {
  label: string
  value: string
}

interface HookOptions {
  includeEmptyOption?: boolean
  padSlug?: string
}
export function useLanguageAndProjectTemplateList(
  options?: HookOptions
): { list: LanguageAndProjectTemplateListItem[]; status: queryStates.QueryState } {
  const includeEmptyOption = options?.includeEmptyOption ?? true
  const {
    data: templatesData,
    error: templatesError,
    loading: templatesLoading,
  } = useQuery<IProjectTemplatesData>(PROJECT_TEMPLATES_QUERY, {
    variables: {
      padSlug: options?.padSlug,
    },
  })
  const { data: langsData, error: langsError, loading: langsLoading } = useQuery<UseLanguagesData>(
    LANG_QUERY
  )
  const list = useMemo(() => {
    if (langsLoading || templatesLoading) {
      return []
    }

    const projectTemplates = templatesData?.projectTemplates
    const languages = langsData?.languages

    if (languages == null || projectTemplates == null) {
      return []
    }

    // if the user has access to the HTML Project Template, then we need to filter out the html/js/css
    // classic language from the list we return
    const hasHTMLProjectTemplate = projectTemplates.find(
      (projectTemplate: ProjectTemplate) => projectTemplate.slug === 'html'
    )

    const _list = languages
      .filter((lang: Language) => !hasHTMLProjectTemplate || lang.id !== 'html')
      .map((lang: Language) => ({ label: lang.name, value: lang.id }))
      .concat(
        projectTemplates.map((pt: ProjectTemplate) => ({
          label: `${pt.name}${pt.slug.startsWith('jupyter') ? ' Notebook' : ''}`,
          value: pt.slug,
        }))
      )
      .sort((a: LanguageAndProjectTemplateListItem, b: LanguageAndProjectTemplateListItem) => {
        return a.label < b.label ? -1 : a.label > b.label ? 1 : 0
      })

    if (includeEmptyOption) {
      // Include an empty option in case there is not a saved default language.
      _list.unshift({ label: 'None', value: '' })
    }

    return _list
  }, [langsData, langsLoading, includeEmptyOption, templatesData, templatesLoading])

  const status = useMemo(() => {
    if (templatesLoading || langsLoading) {
      return queryStates.loading()
    } else if (templatesError != null || langsError != null) {
      return queryStates.error('An error occurred while fetching Languages and Project Templates')
    } else {
      return queryStates.success()
    }
  }, [langsError, langsLoading, templatesError, templatesLoading])

  return {
    list,
    status,
  }
}
