import { gql, useMutation } from '@apollo/client'

import * as queryStates from '../../../queryStates'
import { ForkPadPayload, MutationForkPadArgs, PadForkAttributes } from '../../../types'

export const PAD_FORK_MUTATION = gql`
  mutation ForkPad($input: ForkPadInput!) {
    forkPad(input: $input) {
      pad {
        id
        slug
        title
      }
      message
    }
  }
`

interface IForkPadData {
  forkPad: ForkPadPayload
}

export function usePadFork() {
  const [padFork, { data, error, loading }] = useMutation<IForkPadData, MutationForkPadArgs>(
    PAD_FORK_MUTATION
  )

  let status = queryStates.initial()
  if (loading) {
    status = queryStates.loading()
  } else if (error) {
    status = queryStates.error('There was an error forking the pad.')
  } else if (data?.forkPad?.pad) {
    status = queryStates.success('Pad Forked: ' + data.forkPad.pad.slug)
  }

  return {
    status,
    async padFork(forkAttrs: PadForkAttributes) {
      const response = await padFork({
        variables: { input: { padForkAttributes: forkAttrs } },
        context: { source: 'usePadFork.ts' },
      }).catch((err) => null)
      return {
        pad: response?.data?.forkPad?.pad,
        message: response?.data?.forkPad?.message,
      }
    },
  }
}
