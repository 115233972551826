import React from 'react'

import { ControlBar } from './ControlBar'
import { getControlGroupForSelection } from './SelectionControls/ControlGroups'

interface ISelectionControlBarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selection: any
}

export const SelectionControlBar: React.FC<ISelectionControlBarProps> = ({ selection }) => {
  if (!selection) {
    return null
  }

  const controlGroup = getControlGroupForSelection(selection)
  if (!controlGroup) {
    return null
  }

  return <ControlBar items={controlGroup} />
}

export default SelectionControlBar
