import {
  Email,
  External,
  HelpOutlined,
  Support,
} from '@codinpad/shared-components/components/icons/index.js'
import { styled, useTheme } from '@mui/material'
import Link from '@mui/material/Link'
import React, { useMemo } from 'react'

import { useActiveQuotaHookBuilder } from '../../../../../graphql/hooks/useActiveQuota/useActiveQuota'
import { useUserInfoHookBuilder } from '../../../../../graphql/hooks/users/useCurrentUser/useUserInfo'
import { User } from '../../../../../graphql/types'
import { track } from '../../../../main/coderpad_analytics'

// Note: This is only required to override default Bootstrap styling
const BasicLink = styled(Link)({
  '&:focus': {
    outline: 0,
    textDecoration: 'none',
  },
})

export function useHelpItems() {
  const { userInfo } = useUserInfoHookBuilder<Pick<User, 'email' | 'organizationOwner'>>(`
    email
    organizationOwner
  `)()
  const theme = useTheme()

  const { activeQuota } = useActiveQuotaHookBuilder(`
    paid
    trial
  `)()

  const isContactSalesEnabled = useMemo(() => {
    if (!activeQuota || !userInfo) return false

    if (!activeQuota) return false

    if (activeQuota.paid && userInfo.organizationOwner) return true

    return activeQuota.trial
  }, [activeQuota, userInfo])

  const items = useMemo(() => {
    const its = [
      {
        component: BasicLink,
        endIcon: (
          <External
            sx={{
              '& svg': {
                alignItems: 'center',
                display: 'flex',
                fill: 'inherit',
                justifyContent: 'center',
              },
            }}
          />
        ),
        href: '/resources/docs/',
        icon: (
          <HelpOutlined
            sx={{
              '& svg': {
                alignItems: 'center',
                color: theme.palette.text.primary,
                display: 'flex',
                fill: 'inherit',
                justifyContent: 'center',
              },
            }}
          />
        ),
        key: 'help-center',
        label: 'Help center',
        onClick: () =>
          track('topnav help menu item clicked', { item: 'help center', version: 'xsell' }),
        rel: 'noopener',
        target: '_blank',
      },
      {
        component: BasicLink,
        href: `/contact?email=${userInfo?.email}`,
        icon: (
          <Support
            sx={{
              '& svg': {
                alignItems: 'center',
                color: theme.palette.text.primary,
                display: 'flex',
                fill: 'inherit',
                justifyContent: 'center',
              },
            }}
          />
        ),
        key: 'support',
        label: 'Support',
        onClick: () =>
          track('topnav help menu item clicked', {
            item: 'contact support',
            version: 'xsell',
          }),
      },
    ]

    if (isContactSalesEnabled) {
      its.push({
        component: BasicLink,
        href: `/billing/contact?email=${userInfo?.email}`,
        icon: (
          <Email
            sx={{
              '& svg': {
                alignItems: 'center',
                color: theme.palette.text.primary,
                display: 'flex',
                fill: 'inherit',
                justifyContent: 'center',
              },
            }}
          />
        ),
        key: 'sales',
        label: 'Contact sales',
        onClick: () =>
          track('topnav help menu item clicked', {
            item: 'Contact sales',
            version: 'xsell',
          }),
      })
    }

    return its
  }, [userInfo?.email, isContactSalesEnabled, theme.palette.text.primary])

  return items
}
