import { useCallback, useState } from 'react'

import * as queryStates from '../../../../../graphql/queryStates'
import { useFetch } from '../../../../../utils/fetch/useFetch'

const DEFAULT_ERROR_MSG = 'An unexpected error occurred while uploading image.'

export function useImageUpload(padSlug: string) {
  const fetch = useFetch()
  const [status, setStatus] = useState(queryStates.initial())
  const [fileURL, setFileURL] = useState('')

  const uploadImage = useCallback(
    (file) => {
      setStatus(queryStates.loading())

      const fData = new FormData()
      fData.append('images', file)

      fetch(`/${padSlug}/upload_image`, {
        method: 'POST',
        body: fData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.image_url) {
            setFileURL(result.image_url)
            setStatus(queryStates.success('Image uploaded.'))
          } else {
            setStatus(queryStates.error(result.errors || DEFAULT_ERROR_MSG))
          }
        })
        .catch((err) => {
          setStatus(queryStates.error(DEFAULT_ERROR_MSG, err))
        })
    },
    [padSlug]
  )

  const reset = useCallback(() => {
    setStatus(queryStates.initial())
    setFileURL('')
  }, [])

  return {
    uploadImage,
    status,
    fileURL,
    reset,
  }
}
