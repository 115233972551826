import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import { Box, IconButton, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'

import CustomDialog from '../../../../packs/dashboard/components/CustomDialog/CustomDialog'

const createStyles = makeStyles({
  padLink: {
    marginTop: '20px',
    '& button': {
      marginBottom: '3px',
    },
  },
  checkmarckIcon: {
    color: '#37C773',
  },
  errorIcon: {
    color: '#C73737',
  },
})

interface NewPadData {
  id?: string
  slug?: string
  title?: string
  error?: any
}

interface DuplicatePadDialogResultDisplayProps {
  newPadData: NewPadData
  isOpen: boolean
  close: () => void
}

export const DuplicatePadDialogResultDisplay: React.FC<DuplicatePadDialogResultDisplayProps> = ({
  newPadData,
  isOpen,
  close,
}) => {
  const styles = createStyles()
  const [copied, setCopied] = useState(false)

  const padLink = `${window.location.origin}/${newPadData.slug}`

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(padLink)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={close}
      title={
        newPadData.error ? (
          <>
            <ClearIcon fontSize="large" color="error" sx={{ marginTop: '-4px' }} /> An error has
            occured during pad duplication
          </>
        ) : (
          <>
            <CheckIcon fontSize="large" color="success" sx={{ marginTop: '-4px' }} /> Your new pad
            has been created
          </>
        )
      }
      onConfirm={close}
      confirmLabel="Done"
      hideCancel={true}
    >
      {!newPadData.error && (
        <Box className={styles.padLink}>
          Pad link:{' '}
          <a href={padLink} target="_blank" rel="noreferrer">
            {padLink}
          </a>
          <Tooltip arrow placement="top" title={copied ? 'Copied!' : 'Copy to clipboard'}>
            <IconButton
              style={{ marginLeft: '5px' }}
              className="copyButton"
              aria-label="Copy pad link"
              onClick={handleCopyToClipboard}
              size="large"
            >
              {copied ? <CheckIcon fontSize="small" /> : <FileCopyOutlinedIcon fontSize="small" />}
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </CustomDialog>
  )
}

export default DuplicatePadDialogResultDisplay
