import { gql, QueryHookOptions, useQuery } from '@apollo/client'

import * as queryStates from '../../../queryStates'
import {
  OrganizationMembersSearchResults,
  QueryOrganizationMembersSearchArgs,
} from '../../../types'

export const ORG_MEMBERS_QUERY = gql`
  query OrgMembersSearch(
    $text: String
    $firebaseIds: [String!]
    $page: Int
    $perPage: Int
    $sort: [UserSort!]
    $nameOrEmailFilter: String
  ) {
    organizationMembersSearch(
      text: $text
      firebaseIds: $firebaseIds
      page: $page
      perPage: $perPage
      sort: $sort
      nameOrEmailFilter: $nameOrEmailFilter
    ) {
      records {
        id
        name
        email
        organizationOwner
        organization {
          id
          name
        }
      }
      pageNumber
      pagesTotal
      totalRecords
    }
  }
`

export const ORG_MEMBERS_PAD_USAGE_QUERY = gql`
  query OrgMembersSearch(
    $text: String
    $page: Int
    $perPage: Int
    $sort: [UserSort!]
    $organizationId: Int
    $emailFilter: String
  ) {
    organizationMembersSearch(
      text: $text
      page: $page
      perPage: $perPage
      sort: $sort
      organizationId: $organizationId
      emailFilter: $emailFilter
    ) {
      records {
        email
        padsCreatedLast30
        padsCreatedLifetime
        currentSignInAt
      }
      pageNumber
      pagesTotal
      totalRecords
    }
  }
`

export const ORG_MEMBERS_PADS_AND_QUESTIONS_CREATION_QUERY = gql`
  query OrgMembersSearch(
    $text: String
    $page: Int
    $perPage: Int
    $sort: [UserSort!]
    $organizationId: Int
    $emailFilter: String
    $sinceDays: Int
  ) {
    organizationMembersSearch(
      text: $text
      page: $page
      perPage: $perPage
      sort: $sort
      organizationId: $organizationId
      emailFilter: $emailFilter
      sinceDays: $sinceDays
      isPowerUserQuery: true
    ) {
      records {
        email
        padsStarted
        questionsCreated
        currentSignInAt
      }
      pageNumber
      pagesTotal
      totalRecords
    }
  }
`

interface IOrgMembersData {
  organizationMembersSearch: OrganizationMembersSearchResults
}

export function useOrgMembersSearch(
  variables: QueryOrganizationMembersSearchArgs,
  apolloOptions?: QueryHookOptions,
  isPadUsageQuery: boolean = false,
  isPadsAndQuestionsCreationQuery: boolean = false
) {
  const { data, error, loading, refetch } = useQuery<IOrgMembersData>(
    isPadUsageQuery
      ? ORG_MEMBERS_PAD_USAGE_QUERY
      : isPadsAndQuestionsCreationQuery
      ? ORG_MEMBERS_PADS_AND_QUESTIONS_CREATION_QUERY
      : ORG_MEMBERS_QUERY,
    {
      variables,
      context: {
        source: 'useOrgMembersSearch.ts',
      },
      fetchPolicy: 'cache-and-network',
      ...apolloOptions,
    }
  )

  let status = queryStates.initial()
  if (loading) {
    status = queryStates.loading()
  } else if (error) {
    status = queryStates.error(
      `An error occurred while searching members${error.message ? ': ' + error.message : ''}`
    )
  } else {
    status = queryStates.success()
  }

  const results = data?.organizationMembersSearch
  const members = results?.records || []
  const totalRecords = results?.totalRecords

  const pageInfo = {
    // `pageNumber` from the GQL endpoint is 1-based.
    pageIdx: results?.pageNumber ? results?.pageNumber - 1 : 0,
    filteredPageCount: results?.pagesTotal || 0,
    filteredRecordCount: results?.totalRecords || 0,
    pageSize: variables.perPage || 0,
  }

  return {
    members,
    status,
    pageInfo,
    totalRecords,
    refetch,
  }
}
