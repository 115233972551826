import { AccordionDetails, Paper, useTheme } from '@mui/material'
import { MarkdownOutput } from 'packs/main/markdown_output'
import React from 'react'

export const InstructionDetails = ({
  hidden,
  instructions,
}: {
  hidden: boolean
  instructions: string
}) => {
  const {
    palette: { mode },
  } = useTheme()
  return (
    <AccordionDetails sx={{ paddingTop: 0 }}>
      <Paper
        variant="outlined"
        sx={(theme) => ({
          padding: theme.spacing(2),
          backgroundColor: theme.palette.accordion.details.background,
          wordWrap: 'break-word',
        })}
      >
        <MarkdownOutput value={instructions} hidden={hidden} darkColorScheme={mode === 'dark'} />
      </Paper>
    </AccordionDetails>
  )
}
