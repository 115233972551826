import React, { useCallback, useState } from 'react'

export function useContextMenu() {
  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number } | null>(null)

  const handleContextMenu = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()

      setContextMenu(
        contextMenu == null
          ? {
              mouseX: e.clientX - 2,
              mouseY: e.clientY - 4,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            // (comment lifted from MUI docs [https://mui.com/material-ui/react-menu/#context-menu])
            null
      )
    },
    [contextMenu]
  )

  const handleContextMenuClose = useCallback(() => {
    setContextMenu(null)
  }, [])

  return {
    contextMenu,
    handleContextMenu,
    handleContextMenuClose,
    setContextMenu,
  }
}
