import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import { IconButton, Tooltip } from '@mui/material'
import Cookies from 'js-cookie'
import React, { useCallback } from 'react'

import { checkDashboardDarkMode } from '../../../../utils/checkFeatureFlag'
import { useAppStateProperty } from '../AppState/AppStateProvider'

export function ThemeToggler() {
  const { value: darkMode, set: setDarkMode } = useAppStateProperty('darkMode')

  const toggle = useCallback(() => {
    setDarkMode(!darkMode)
    const dashboardSettings = JSON.parse(Cookies.get('dashboard_config') ?? '{}')

    Cookies.set(
      'dashboard_config',
      JSON.stringify({ ...dashboardSettings, dark_color_scheme: !darkMode })
    )
    if (!darkMode) {
      document.body.classList.remove('dashboard--light')
      document.body.classList.add('dashboard--dark')
    } else {
      document.body.classList.remove('dashboard--dark')
      document.body.classList.add('dashboard--light')
    }
  }, [darkMode, setDarkMode])

  if (!checkDashboardDarkMode()) {
    return null
  }

  return (
    <Tooltip title={darkMode ? 'Switch to light mode' : 'Switch to dark mode'}>
      <IconButton onClick={toggle} color="primary">
        {darkMode ? <DarkModeIcon /> : <LightModeIcon />}
      </IconButton>
    </Tooltip>
  )
}
