import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

export const QUESTION_COUNTS_QUERY = gql`
  query QuestionCounts {
    all: questionsSearch {
      totalRecords
    }
    user: questionsSearch(userOnly: true) {
      totalRecords
    }
    examples: exampleQuestionsSearch {
      totalRecords
    }
    drafts: questionsSearch(isDraft: true) {
      totalRecords
    }
  }
`

export type QuestionCountsQuery = {
  all: {
    totalRecords: number
  }
  user: {
    totalRecords: number
  }
  examples: {
    totalRecords: number
  }
  drafts: {
    totalRecords: number
  }
}

const countKeys = ['all', 'user', 'examples', 'drafts'] as const

export function useQuestionCounts() {
  const { data, loading } = useQuery<QuestionCountsQuery>(QUESTION_COUNTS_QUERY)
  return {
    loading,
    counts: countKeys.reduce(
      (memo, key) => ({
        ...memo,
        [key]: (data || {})[key]?.totalRecords || 0,
      }),
      {}
    ) as Record<typeof countKeys[number], number>,
  }
}
