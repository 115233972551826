import { SvgIcon } from '@mui/material'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import { ColorPicker } from '../../Toolbar/ColorPicker/ColorPicker'
import { FontColor } from '../../Toolbar/icons'
import { ToolbarsContext } from '../../Whiteboard'
import { ToolControlItem } from './ToolControlItem'

export const FontColorControl: FC = () => {
  const { setDefaultFontColor, wbEngine } = useContext(ToolbarsContext)
  const [currentColor, setCurrentColor] = useState('inherit')

  useEffect(() => {
    setCurrentColor(wbEngine?.selectedObjects[0]?.stroke ?? 'inherit')
  }, [wbEngine?.selectedObjects])

  const handleColorSelection = useCallback(
    (color) => {
      wbEngine!.setOnSelection({ stroke: color, fill: color })
      setCurrentColor(color)
      setDefaultFontColor(color)
    },
    [setDefaultFontColor, wbEngine]
  )

  if (!wbEngine) return null

  return (
    <>
      <ToolControlItem
        tooltip="Border Color"
        popperMenu={({ open, setOpen }) => (
          <ColorPicker
            onChangeColor={handleColorSelection}
            currentColor={wbEngine.selectedObjects[0]?.stroke}
          />
        )}
      >
        <SvgIcon
          component={FontColor}
          sx={{
            '#colorBar': {
              color: currentColor,
            },
          }}
        />
      </ToolControlItem>
    </>
  )
}
