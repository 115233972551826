import { gql, useQuery } from '@apollo/client'

import * as queryStates from '../../../queryStates'
import { Plan } from '../../../types'

export const CURRENT_PLAN_QUERY = gql`
  query CurrentPlan {
    plan {
      id
      title
      widgetSubTitle
      widgetDescription
      description
      billingCyclePadLimit
      userAccountLimit
      monthlyPrice
      formattedMonthlyPrice
      formattedAnnualPrice
      overagePrice
      formattedOveragePrice
      current
      planCategorization
      legacy
      legacyOf
      upgradeOrder
      annual
      hasAnnualAlternative
      upgradeOrder
    }
  }
`

export interface UseCurrentPlanData {
  plan: Plan
}

export function useCurrentPlan() {
  const { data, error, loading, refetch } = useQuery<UseCurrentPlanData>(CURRENT_PLAN_QUERY, {
    context: {
      source: 'useCurrentPlan.ts',
    },
  })

  const currentPlan = data?.plan

  let status = queryStates.initial()
  if (loading) {
    status = queryStates.loading()
  } else if (error) {
    status = queryStates.error(
      `An error occurred while fetching current plan${error.message ? ': ' + error.message : ''}`
    )
  } else {
    status = queryStates.success()
  }

  return {
    currentPlan,
    status,
    refetch,
  }
}
