import Button from '@mui/material/Button'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'

export const WarningButton = withStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.error.main,
    },
    outlined: {
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.error.main,
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.error.main,
        color: '#fff',
      },
    },
    contained: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
      color: theme.palette.error.contrastText,
    },
  })
)(Button)
