import { useDroppable } from '@dnd-kit/core'
import { useTheme } from '@mui/material'
import React, { FC, useCallback } from 'react'
import { FileWithPath, useDropzone } from 'react-dropzone'

import { useDragAndDrop } from '../DragAndDrop/DragAndDropContext'
import { FileTooLargeDialog } from '../DragAndDrop/FileMessageDialog/dialogs/FileTooLargeDialog'
import { MaxFileSize, PrettyMaxFileSize } from './config'

export interface FolderDropZoneProps {
  path: string
  onOpen?: () => void
  onFileUpload?: (files: FileWithPath[], destination: string) => void
}

export const FolderDropZone: FC<FolderDropZoneProps> = ({
  path,
  children,
  onOpen,
  onFileUpload,
}) => {
  const { setFileMessageDialogQueue, popFileMessageQueue } = useDragAndDrop()
  const theme = useTheme()
  const { setNodeRef, isOver } = useDroppable({
    id: path,
    data: {
      type: 'folder',
      path,
      onOpen,
    },
  })

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // filter out node modules directories
      // TODO: determine what other dependency directories we should filter out
      acceptedFiles = acceptedFiles.filter(
        (file: FileWithPath) => !file.path?.includes('node_modules')
      )
      onFileUpload?.(acceptedFiles, path)
    },
    [onFileUpload, path]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noDragEventsBubbling: true,
    validator(file) {
      // reject files over our max file size
      if (file.size > MaxFileSize) {
        setFileMessageDialogQueue((queue) => [
          ...queue,
          FileTooLargeDialog({
            file,
            onOk: popFileMessageQueue,
          }),
        ])
        return {
          code: 'file-too-large',
          message: `File is too large (max ${PrettyMaxFileSize}MB)`,
        }
      }
      return null
    },
  })

  return (
    <div {...getRootProps()}>
      <input
        {...getInputProps()}
        // @ts-ignore
        webkitdirectory=""
      />
      <div
        ref={setNodeRef}
        style={{
          backgroundColor:
            isOver || isDragActive ? theme.palette.filePane?.activeBackground : 'transparent',
        }}
      >
        {children}
      </div>
    </div>
  )
}
