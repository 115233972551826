import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import * as queryStates from '../../../queryStates'
import { ExampleQuestion } from '../../../types'

function buildQueryLiteral(exampleFields: string) {
  return gql`
  query ExampleQuestion($exampleQuestionId: String!) {
    exampleQuestion(id: $exampleQuestionId) {
      id
      title
      ... on ExampleQuestion {
        ${exampleFields}
      }
    }
  }
`
}

export function useMakeUseExampleQuestion(exampleFields: string) {
  return useMemo(() => memoizedMakeUseQuestion(exampleFields), [exampleFields])
}

function memoizedMakeUseQuestion(exampleFields: string) {
  const query = buildQueryLiteral(exampleFields)

  return function useQuestion(exampleQuestionId?: string) {
    const { data, loading, error } = useQuery<{ exampleQuestion: ExampleQuestion }>(query, {
      variables: {
        exampleQuestionId,
      },
      skip: !exampleQuestionId,
    })

    let status = queryStates.initial()
    if (loading) {
      status = queryStates.loading()
    } else if (error) {
      status = queryStates.error('Error while finding example question.')
    } else {
      status = queryStates.success()
    }

    return {
      status,
      exampleQuestion: data?.exampleQuestion,
    }
  }
}
