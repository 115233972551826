import { User } from 'graphql/types'

import { cgBaseUrl } from './codingameService'

export function userBillingPageRoute(user: User | undefined): string {
  const isAdmin = user?.organizationOwner
  const onBusinessEnterprisePlan =
    user?.activeQuota.planObj?.title.includes('Business') ||
    user?.activeQuota.planObj?.title.includes('Enterprise')

  // If the user has a codingameXsell plan, redirect to codingame
  if (user?.activeQuota?.planObj?.codingameXsellPlan) {
    return cgBaseUrl + '/dashboard/account/billing/plans'
  }

  // If the user is on the Free plan, go directly to the pricing page
  if (user?.activeQuota.plan === 0) {
    return '/app_pricing?upgrade=true'
  }

  // Route users to the contact page or the plans page.
  // If a user is NOT an Admin rails will redirect to the members page.
  return isAdmin && onBusinessEnterprisePlan && user?.email
    ? `/billing/contact?email=${encodeURIComponent(user.email)}&type=upgrade`
    : '/billing/plans'
}

export const cgUsersPageRoute = `${cgBaseUrl}/dashboard/account/users`
export const cgBillingPageRoute = `${cgBaseUrl}/dashboard/account/billing/informations`
