import React from 'react'

import { ControlBar } from '../ControlBars/ControlBar'
import { getTool, ITool } from './toolDefinitions'
import { ToolItem } from './ToolItem/ToolItem'

// We are going to assume that these tools do exist, so cast them to ITool to avoid "possibly undefined" errors.
const selectTool = getTool('select') as ITool
const rectangleTool = getTool('rectangle') as ITool
const cylinderTool = getTool('cylinder') as ITool
const diamondTool = getTool('diamond') as ITool
const ellipseTool = getTool('ellipse') as ITool
const textTool = getTool('text') as ITool
const lineTool = getTool('lineStraight') as ITool
const arrowTool = getTool('lineArrow') as ITool
const doubleArrowTool = getTool('lineDoubleArrow') as ITool
const pencilTool = getTool('pencil') as ITool
const uploadTool = getTool('upload') as ITool

interface IToolbarProps {
  activeTool: string
  setTool: (tool: string) => void
  selectImage: () => void
}

/**
 * Toolbar for a board to provide controls to select tools and show what tool is selected, as well as trigger image
 * uploads and undo/redo.
 */
export const Toolbar: React.FC<IToolbarProps> = (props) => {
  const { activeTool, setTool, selectImage } = props

  return (
    <ControlBar
      isVertical={true}
      items={[
        <ToolItem
          key="select"
          toolName={selectTool.name}
          Icon={selectTool.icon}
          onClick={setTool}
          isActive={activeTool === selectTool.name}
          toolTitle={selectTool.title}
          tooltipPlacement="right"
        />,
        <ToolItem
          key="rectangle"
          toolName={rectangleTool.name}
          Icon={rectangleTool.icon}
          onClick={setTool}
          isActive={activeTool === rectangleTool.name}
          toolTitle={rectangleTool.title}
          tooltipPlacement="right"
        />,
        <ToolItem
          key="cylinder"
          toolName={cylinderTool.name}
          Icon={cylinderTool.icon}
          onClick={setTool}
          isActive={activeTool === cylinderTool.name}
          toolTitle={cylinderTool.title}
          tooltipPlacement="right"
        />,
        <ToolItem
          key="diamond"
          toolName={diamondTool.name}
          Icon={diamondTool.icon}
          onClick={setTool}
          isActive={activeTool === diamondTool.name}
          toolTitle={diamondTool.title}
          tooltipPlacement="right"
        />,
        <ToolItem
          key="ellipse"
          toolName={ellipseTool.name}
          Icon={ellipseTool.icon}
          onClick={setTool}
          isActive={activeTool === ellipseTool.name}
          toolTitle={ellipseTool.title}
          tooltipPlacement="right"
        />,
        <ToolItem
          key="text"
          toolName={textTool.name}
          Icon={textTool.icon}
          onClick={setTool}
          isActive={activeTool === textTool.name}
          toolTitle={textTool.title}
          tooltipPlacement="right"
        />,
        <ToolItem
          key="line"
          toolName={lineTool.name}
          Icon={lineTool.icon}
          onClick={setTool}
          isActive={activeTool === lineTool.name}
          toolTitle={lineTool.title}
          tooltipPlacement="right"
        />,
        <ToolItem
          key="arrow"
          toolName={arrowTool.name}
          Icon={arrowTool.icon}
          onClick={setTool}
          isActive={activeTool === arrowTool.name}
          toolTitle={arrowTool.title}
          tooltipPlacement="right"
        />,
        <ToolItem
          key="doubleArrow"
          toolName={doubleArrowTool.name}
          Icon={doubleArrowTool.icon}
          onClick={setTool}
          isActive={activeTool === doubleArrowTool.name}
          toolTitle={doubleArrowTool.title}
          tooltipPlacement="right"
        />,
        <ToolItem
          key="pencil"
          toolName={pencilTool.name}
          Icon={pencilTool.icon}
          onClick={setTool}
          isActive={activeTool === pencilTool.name}
          toolTitle={pencilTool.title}
          tooltipPlacement="right"
        />,
        <ToolItem
          key="upload"
          toolName={uploadTool.name}
          Icon={uploadTool.icon}
          onClick={selectImage}
          toolTitle={uploadTool.title}
          tooltipPlacement="right"
        />,
      ]}
    />
  )
}
