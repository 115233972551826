import { Box, Tooltip, useTheme } from '@mui/material'
import React, { useCallback, useContext, useMemo } from 'react'

import { ToolbarsContext } from '../../Whiteboard'

interface IToolItemProps {
  isActive?: boolean
  isDisabled?: boolean
  toolTitle: string
  toolName: string
  tooltipPlacement?: 'right' | 'left' | 'top' | 'bottom'
  Icon: React.ComponentType
  onClick: (toolName: string) => void
  color?: string
}

export const ToolItem: React.FC<IToolItemProps> = ({
  isActive,
  isDisabled,
  toolName,
  toolTitle,
  tooltipPlacement,
  onClick,
  Icon,
  color,
}) => {
  const theme = useTheme()
  const { drawingTheme } = useContext(ToolbarsContext)

  const fillColor = useMemo(() => {
    if (isDisabled) return drawingTheme.toolbarColorDisabled

    return color || drawingTheme.toolbarColor
  }, [color, drawingTheme.toolbarColor, drawingTheme.toolbarColorDisabled, isDisabled])

  const handleClick = useCallback(() => {
    if (!isDisabled) {
      onClick(toolName)
    }
  }, [onClick, toolName, isDisabled])

  return (
    <Tooltip title={toolTitle} placement={tooltipPlacement ?? 'top'}>
      <Box
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          borderRadius: theme.spacing(0.5),
          backgroundColor: isActive ? theme.palette.primary.main : 'initial',
          '& svg path, & svg rect, & svg circle': {
            fill: fillColor,
          },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={48}
        height={40}
        role="button"
      >
        <Icon />
      </Box>
    </Tooltip>
  )
}
