import { Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'classnames'
import React from 'react'

import { fileIdToPath } from '../../../../../utils/multifile'
import { FileIcon } from '../../../../main/Monaco/FilePane/FileIcon'
import { MonacoFile } from '../../../../main/Monaco/FilePane/utils/types'
import { WrappedToDepth } from '../../../../main/Monaco/FilePane/utils/WrappedToDepth'

interface IFileLine {
  depth: number
  file: MonacoFile
  activeFileId: string
  setActiveFile: (fileId: string) => void
  showHighlight: boolean
  showSubHighlights: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  file: {
    backgroundColor: theme.palette.filePane?.background,
  },
  active: {
    backgroundColor: theme.palette.filePane?.activeBackground,
    color: theme.palette.filePane?.activeText,
  },
  inactive: {
    '&:hover': {
      backgroundColor: theme.palette.filePane?.highlightBackground,
    },
  },
}))

export const FileLine = ({
  depth,
  file,
  activeFileId,
  setActiveFile,
  showHighlight,
  showSubHighlights,
}: IFileLine) => {
  const activeFilePath = fileIdToPath(activeFileId)
  const active = activeFileId === file.fileId
  const activate = () => setActiveFile(file.fileId)
  const prettyName = file == null ? '' : file.name.split('/').reverse()[0]
  const styles = useStyles()

  const highlightDepth = activeFileId == null ? 0 : activeFilePath.split('/').length - 1

  return (
    <div>
      <div
        className={clsx(
          'file',
          styles.file,
          { active: active },
          active ? styles.active : styles.inactive
        )}
        onClick={activate}
        data-testid="file"
      >
        <WrappedToDepth
          depth={depth}
          highlightDepth={highlightDepth}
          showHighlight={showHighlight}
          showSubHighlights={showSubHighlights}
        >
          <div className="file-wrapper">
            <FileIcon fileName={file.name} isDarkTheme={false} />
            <div className="file-name">
              <Typography fontSize="inherit">{prettyName}</Typography>
            </div>
          </div>
        </WrappedToDepth>
      </div>
    </div>
  )
}
