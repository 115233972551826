import { Help } from '@codinpad/shared-components/components/icons/index.js'
import MenuButton from '@codinpad/shared-components/components/topbar/buttons/MenuButton.js'
import SettingsButton from '@codinpad/shared-components/components/topbar/buttons/SettingsButton.js'
import UserButton from '@codinpad/shared-components/components/topbar/buttons/UserButton.js'
import TextButton from '@codinpad/shared-components/components/topbar/TextButton.js'
import Topbar from '@codinpad/shared-components/components/topbar/TopBar.js'
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline'
import React, { FC } from 'react'
import { useCodingameLgUp } from 'utils/hooks/useCodingameLgUp'

import { Organization, User } from '../../../../graphql/types'
import { track } from '../../../main/coderpad_analytics'
import { useHelpItems } from './hooks/useHelpItems'
import { useSettingsItems } from './hooks/useSettingsItems'
import { useUserItems } from './hooks/useUserItems'
import { ThemeToggler } from './ThemeToggler'

interface SharedHeaderProps {
  organization?: Organization
  currentUser?: User
  noRouter?: boolean
  verticalOffset?: number | string
}

const SharedHeader: FC<SharedHeaderProps> = ({ currentUser, verticalOffset = 0 }) => {
  const fullName = currentUser?.name ?? ''

  const isBigScreen = useCodingameLgUp()

  const helpButtonItems = useHelpItems()
  const settingsButtonItems = useSettingsItems()
  const userMenuItems = useUserItems()

  return (
    <>
      {/* Ugly sx hacks required for ScopedCssBaseline to have correct margin and padding */}
      <ScopedCssBaseline
        sx={{
          paddingTop: verticalOffset,
          paddingRight: isBigScreen ? '12px' : '-4px',
        }}
      >
        <Topbar>
          <TextButton
            aria-controls="beamer-action-menu"
            data-testid="beamer-action-menu-opener"
            className="beamerTrigger"
            onClick={() => track('topnav item clicked', { item: "what's new", version: 'xsell' })}
          >
            What's New
          </TextButton>
          <ThemeToggler />
          <MenuButton icon={<Help />} menuItems={helpButtonItems} title="Help" />
          <SettingsButton
            menuItems={settingsButtonItems}
            onClick={() =>
              track('topnav item clicked', { item: 'settings gear', version: 'xsell' })
            }
          />
          <UserButton
            avatar={{ fullName }}
            menuItems={userMenuItems}
            onClick={() => track('topnav item clicked', { item: 'user menu', version: 'xsell' })}
          />
        </Topbar>
      </ScopedCssBaseline>
    </>
  )
}

export default SharedHeader
