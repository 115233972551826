import WhiteboardEngine from '@codinpad/wbengine-client'
import { useCallback, useEffect } from 'react'

export const useResizeHandler = (wbEngine: WhiteboardEngine | null) => {
  const resizeHandler = useCallback(() => {
    wbEngine?.resize()
  }, [wbEngine])

  useEffect(() => {
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [wbEngine, resizeHandler])
}
