import { DependencyList, useEffect, useRef, useState } from 'react'

/**
 * comes from https://github.com/facebook/react/blob/c91a1e03be54733a7dbfcb5663d7a9e8606ab1c1/packages/react-reconciler/src/ReactFiberHooks.new.js#L388
 */
function areHookInputsEqual(nextDeps: DependencyList, prevDeps: DependencyList) {
  for (let i = 0; i < prevDeps.length && i < nextDeps.length; i++) {
    if (Object.is(nextDeps[i], prevDeps[i])) {
      continue
    }
    return false
  }
  return true
}

/**
 * Mix between an effect and a state
 * Can be used the same way as `useEffect` but takes a `setState` parameter and the effect returns the state value
 * The difference between this and a combination of an effect and a state is that this hook resets the state to the default value when the deps change
 */
export default function useStateEffect<S>(
  callback: (setState: (state: S) => void) => void | (() => void | undefined),
  deps: DependencyList,
  defaultState: S
) {
  const previousDepsRef = useRef<DependencyList>(deps)
  const [state, setState] = useState<S>(defaultState)
  useEffect(() => {
    previousDepsRef.current = deps
    setState(defaultState)
    return callback(setState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  if (!areHookInputsEqual(deps, previousDepsRef.current)) {
    // It happens when the deps changed but the effect wasn't called yet
    // Force the state value to the default one to prevent the desync
    return defaultState
  }
  return state
}
