import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import * as queryStates from '../../../queryStates'

export function buildQueryLiteral(questionFields: string) {
  return gql`
  query QuestionDetail($questionId: Int!) {
    question(id: $questionId) {
      id
      title
      ... on Question {
        ${questionFields}
      }
    }
  }
`
}

export function useMakeUseQuestion(questionFields: string) {
  return useMemo(() => memoizedMakeUseQuestion(questionFields), [questionFields])
}

function memoizedMakeUseQuestion(questionFields: string) {
  const query = buildQueryLiteral(questionFields)

  return function useQuestion(questionId?: number) {
    const { data, loading, error } = useQuery(query, {
      variables: {
        questionId,
      },
      skip: !questionId,
    })

    let status = queryStates.initial()
    if (loading) {
      status = queryStates.loading()
    } else if (error) {
      status = queryStates.error('Error while finding question.')
    } else {
      status = queryStates.success()
    }

    return {
      status,
      question: data?.question,
    }
  }
}
