import { SvgIcon } from '@mui/material'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import {
  BoldFont,
  FontStyle,
  ItalicFont,
  StrikethroughFont,
  UnderlineFont,
} from '../../Toolbar/icons'
import { ToolbarsContext } from '../../Whiteboard'
import { ControlIconGrid } from '../ControlIconGrid'
import { ToolControlItem } from './ToolControlItem'

export const FontStyleControl: FC = () => {
  const { wbEngine, setDefaultFontStyle } = useContext(ToolbarsContext)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderlined, setIsUnderlined] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)

  useEffect(() => {
    const selectedObj = wbEngine?.selectedObjects[0]
    setIsBold(selectedObj?.fontWeight === 'bold')
    setIsItalic(selectedObj?.fontStyle === 'italic')
    setIsUnderlined(selectedObj?.underline ?? false)
    setIsStrikethrough(selectedObj?.linethrough ?? false)
  }, [wbEngine?.selectedObjects, setIsBold, setIsItalic, setIsUnderlined, setIsStrikethrough])

  const handleBoldSelection = useCallback(
    (isBold: boolean) => {
      const newFontWeight = isBold ? 'bold' : 'normal'
      wbEngine!.setOnSelection({ fontWeight: newFontWeight })
      setIsBold(isBold)
      setDefaultFontStyle('fontWeight', newFontWeight)
    },
    [setDefaultFontStyle, wbEngine]
  )

  const handleItalicSelection = useCallback(
    (isItalic: boolean) => {
      const newFontStyle = isItalic ? 'italic' : 'normal'
      wbEngine!.setOnSelection({ fontStyle: newFontStyle })
      setIsItalic(isItalic)
      setDefaultFontStyle('fontStyle', newFontStyle)
    },
    [setDefaultFontStyle, wbEngine]
  )

  const handleUnderlinedSelection = useCallback(
    (isUnderlined: boolean) => {
      wbEngine!.setOnSelection({ underline: isUnderlined })
      setIsUnderlined(isUnderlined)
      setDefaultFontStyle('isUnderlined', isUnderlined)
    },
    [setDefaultFontStyle, wbEngine]
  )

  const handleStrikethroughSelection = useCallback(
    (isStrikethrough: boolean) => {
      wbEngine!.setOnSelection({ linethrough: isStrikethrough })
      setIsStrikethrough(isStrikethrough)
      setDefaultFontStyle('isStrikethrough', isStrikethrough)
    },
    [setDefaultFontStyle, wbEngine]
  )

  if (!wbEngine) return null

  return (
    <>
      <ToolControlItem
        tooltip="Border Color"
        popperMenu={({ open, setOpen }) => (
          <ControlIconGrid
            numCols={4}
            icons={[
              {
                Icon: <SvgIcon component={BoldFont} />,
                onClick: () => handleBoldSelection(!isBold),
                active: isBold,
              },
              {
                Icon: <SvgIcon component={ItalicFont} />,
                onClick: () => handleItalicSelection(!isItalic),
                active: isItalic,
              },
              {
                Icon: <SvgIcon component={UnderlineFont} />,
                onClick: () => handleUnderlinedSelection(!isUnderlined),
                active: isUnderlined,
              },
              {
                Icon: <SvgIcon component={StrikethroughFont} />,
                onClick: () => handleStrikethroughSelection(!isStrikethrough),
                active: isStrikethrough,
              },
            ]}
          />
        )}
      >
        <SvgIcon component={FontStyle} />
      </ToolControlItem>
    </>
  )
}
