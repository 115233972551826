export class EventEmitter {
  private events: { [key: string]: ((...args: any) => void)[] } = {}

  on(eventName: string, handler: (...args: any) => void) {
    if (!this.events[eventName]) {
      this.events[eventName] = []
    }

    this.events[eventName].push(handler)
  }

  remove(eventName: string, handler: (...args: any) => void) {
    if (this.events[eventName]) {
      this.events[eventName] = this.events[eventName].filter((f) => f !== handler)
    }
  }

  emit(eventName: string, data?: any) {
    if (this.events[eventName]) {
      this.events[eventName].forEach((cb) => cb(data))
    }
  }
}
