import { dirname, join } from 'path-browserify'
import React, { useCallback } from 'react'

import { ReactComponent as EmptyFolderIcon } from '../../../../../../assets/images/projects/emptyFolder.svg'
import { useActiveEnvironment } from '../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { DirectoryItemInput } from '../DirectoryItemInput'
import { WrappedToDepth } from '../utils/WrappedToDepth'

interface IRenameFolderProps {
  depth: number
  folderPath: string
  oldFolderName: string
  onExit: () => void
  showHighlight: boolean
  showSubHighlights: boolean
}
export const RenameFolder: React.FC<IRenameFolderProps> = ({
  depth,
  folderPath,
  oldFolderName,
  onExit,
  showHighlight,
  showSubHighlights,
}) => {
  const { activeFile, renameFolder } = useActiveEnvironment()

  const handleSubmit = useCallback(
    (folderName: string) => {
      renameFolder(join(folderPath, oldFolderName), join(folderPath, folderName))
      onExit()
    },
    [folderPath, oldFolderName, renameFolder, onExit]
  )

  if (activeFile == null) {
    return null
  }

  const activeFilePath = dirname(activeFile.path)
  const highlightDepth = activeFilePath.split('/').length - 1

  return (
    <div className="directory-item-input">
      <WrappedToDepth
        depth={depth}
        highlightDepth={highlightDepth}
        showHighlight={showHighlight}
        showSubHighlights={showSubHighlights}
        opaque={true}
      >
        <DirectoryItemInput
          defaultValue={oldFolderName}
          onExit={onExit}
          onSubmit={handleSubmit}
          icon={<EmptyFolderIcon className="folder-icon" />}
        />
      </WrappedToDepth>
    </div>
  )
}
