import { useCallback, useState } from 'react'

import { QueryQuestionsSearchArgs } from '../../../types'

export type SearchParams = QueryQuestionsSearchArgs

// Define the available search arg names.
export type SearchParamNames =
  | 'page'
  | 'text'
  | 'language'
  | 'difficulty'
  | 'takeHome'
  | 'userOnly'
  | 'sort'
  | 'testCasesEnabled'

// Define the default search params for the question list.
export const defaultSearchParams: SearchParams = {
  perPage: 10,
  page: 0,
  text: '',
}

/**
 * Custom hook to maintain state for question search filters. Exposes the filters state, as well as a function to set a
 * single filter value and a function to set multiple filter values. This hook does no fetching of data; it is
 * intended to encapsulate any special filter logic related to question searching.
 *
 * @param searchParams default search param values.
 */
export function useQuestionFilters(searchParams?: SearchParams) {
  const [filters, setFilterValues] = useState({ ...defaultSearchParams, ...searchParams })

  // Callback to set multiple search args.
  const setFilters = useCallback(
    (args: SearchParams) => {
      const newFilters = { ...filters, ...args }
      // If changing a filter other than `sort` and no page is specified, zero out the page filter value.
      if (!args.sort && !args.page) {
        newFilters.page = 0
      }
      setFilterValues(newFilters)
    },
    [filters]
  )

  // Callback to set a single search arg.
  const setFilter = useCallback(
    (param: SearchParamNames, value) => {
      setFilters({ [param]: value })
    },
    [filters]
  )

  return {
    setFilters,
    setFilter,
    filters,
  }
}
