import { SvgIcon } from '@mui/material'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import { ColorPicker } from '../../Toolbar/ColorPicker/ColorPicker'
import { BorderColor } from '../../Toolbar/icons'
import { ToolbarsContext } from '../../Whiteboard'
import { ToolControlItem } from './ToolControlItem'

export const LineColorControl: FC = () => {
  const { setDefaultLineColor, wbEngine } = useContext(ToolbarsContext)
  const [currentColor, setCurrentColor] = useState('inherit')

  useEffect(() => {
    let line = null
    if (wbEngine?.selectedObjects?.length && wbEngine.selectedObjects[0].isLineContainer) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      line = wbEngine?.selectedObjects[0].objects.find(
        (obj: any) => obj.type === 'Line' || obj.type === 'Path'
      )
    } else {
      line = wbEngine?.selectedObjects[0]
    }
    setCurrentColor(line?.stroke ?? 'inherit')
  }, [wbEngine?.selectedObjects])

  const handleColorSelection = useCallback(
    (color) => {
      wbEngine!.setOnSelection({ stroke: color })
      setCurrentColor(color)
      setDefaultLineColor(color)
    },
    [setDefaultLineColor, wbEngine]
  )

  if (!wbEngine) return null

  return (
    <>
      <ToolControlItem
        tooltip="Line Color"
        popperMenu={({ open, setOpen }) => (
          <ColorPicker
            onChangeColor={handleColorSelection}
            currentColor={wbEngine.selectedObjects[0]?.stroke}
          />
        )}
      >
        <SvgIcon
          component={BorderColor}
          sx={{
            '#colorBar': {
              color: currentColor,
            },
          }}
        />
      </ToolControlItem>
    </>
  )
}
