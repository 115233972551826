import { Box, styled } from '@mui/material'
import clsx from 'clsx'
import React from 'react'

const Wrapper = styled(Box)(({ theme }) => ({
  borderLeftColor: theme.palette.filePane?.background,
  borderLeftWidth: '1px',
  borderLeftStyle: 'solid',
  '&.active': {
    borderLeftColor: theme.palette.filePane?.activeBackground,
  },
  '&.lastWrapper': {
    display: 'flex',
  },
  '&.highlightDepth': {
    borderLeftColor: theme.palette.filePane?.folderHighlightDepth,
  },
  '&.subHighlightDepth': {},
  '&.highlightDepthOpaque': {
    borderLeftColor: theme.palette.filePane?.folderHighlightDepthOpaque,
  },
  '&.subHighlightDepthOpaque': {
    borderLeftColor: theme.palette.filePane?.folderSubHighlightDepthOpaque,
  },
}))

interface IWrappedToDepthProps {
  children: React.ReactNode | React.ReactNode[]
  currentIteration?: number
  active?: boolean
  depth: number
  highlightDepth: number
  showHighlight?: boolean
  showSubHighlights?: boolean
  opaque?: boolean
}
export const WrappedToDepth: React.FC<IWrappedToDepthProps> = ({
  children,
  currentIteration = 0,
  active = false,
  depth,
  highlightDepth,
  showHighlight = false,
  showSubHighlights = false,
  opaque = false,
}) => {
  const isHighlight = currentIteration === highlightDepth - 1
  return currentIteration === depth ? (
    <>{children}</>
  ) : (
    <Wrapper
      className={clsx('directory-item-wrapper', {
        active,
        lastWrapper: currentIteration === depth - 1,
        highlightDepth: showHighlight && highlightDepth != null && isHighlight && !opaque,
        highlightDepthOpaque: showHighlight && highlightDepth != null && isHighlight && opaque,
        subHighlightDepth: showSubHighlights && highlightDepth != null && !isHighlight && !opaque,
        subHighlightDepthOpaque:
          showSubHighlights && highlightDepth != null && !isHighlight && opaque,
      })}
    >
      <WrappedToDepth
        currentIteration={currentIteration + 1}
        depth={depth}
        active={active}
        highlightDepth={highlightDepth}
        showHighlight={showHighlight}
        showSubHighlights={showSubHighlights}
        opaque={opaque}
      >
        {children}
      </WrappedToDepth>
    </Wrapper>
  )
}
