import { TabPanel as MUITabPanel, useTabContext as useMUITabContext } from '@mui/lab'
import { Box, styled, SxProps } from '@mui/material'
import React, { useContext } from 'react'

import { TabsContext } from './TabsContext'

const StyledMUITabPanel = styled(MUITabPanel)({
  // The default TabPanel is opinionated about padding. To offer more flexibility, remove that default padding.
  padding: 0,
})

interface TabPanelProps {
  /**
   * Defaults to `true`. When `false`, the tab panel contents will not be conditionally rendered and will stay mounted.
   * This is useful if you will be rendering contents into a tab panel that should stay mounted even when the tab is
   * not the active tab. In the CoderPad context, this has traditionally been the program output, project output/shell,
   * and custom file tabs.
   */
  unmountOnHidden?: boolean
  /** The value for the TabPanel. This is what ties a panel to its corresonding tab. */
  value: string
  /** Provided for convenience. */
  sx?: SxProps
  /** Provided for compatibility with the MUI `styled` utility. */
  className?: string
}

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  unmountOnHidden,
  value,
  sx,
  className = '',
}) => {
  return unmountOnHidden ?? true ? (
    <StyledMUITabPanel
      value={value}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      className={className}
    >
      {children}
    </StyledMUITabPanel>
  ) : (
    <AlternateTabPanel value={value} className={className}>
      {children}
    </AlternateTabPanel>
  )
}

/**
 * The MUI TabPanel conditionally renders the tab's contents. In some cases we may not want to unmount the tab
 * contents. This alternate implementation of a TabPanel just hides the tab's contents when the tab is inactive.
 * This alternate verison of TabPanel is interchangeable with the MUI TabPanel.
 */
const AlternateTabPanel: React.FC<{ value: string; className: string }> = ({
  children,
  className,
  value,
}) => {
  const muiContext = useMUITabContext()
  const { value: activeTabValue } = useContext(TabsContext)

  return (
    <Box
      role="tabpanel"
      id={`${muiContext?.idPrefix}-P-${value}`}
      aria-labelledby={`${muiContext?.idPrefix}-T-${value}`}
      sx={{ display: activeTabValue !== value ? 'none' : undefined }}
      className={className}
    >
      {children}
    </Box>
  )
}
