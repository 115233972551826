import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { memoize } from 'lodash'
import { useMemo } from 'react'

import * as queryStates from '../../../queryStates'
import { ExampleQuestionsSearchResults, QueryExampleQuestionsSearchArgs } from '../../../types'

export function buildQuestionQueryLiteral(fieldFragment: string) {
  return gql`
    query ExampleQuestions(
      $page: Int
      $perPage: Int
      $text: String
      $difficulty: QuestionDifficulty
      $testCasesEnabled: Boolean
      $language: String
      $sort: [ExampleQuestionSort!]
      $globalQuestionTagIds: [Int!]
    ) {
      examples: exampleQuestionsSearch(
        page: $page
        perPage: $perPage
        text: $text
        difficulty: $difficulty
        testCasesEnabled: $testCasesEnabled
        language: $language
        sort: $sort
        globalQuestionTagIds: $globalQuestionTagIds
      ) {
        totalRecords
        pagesTotal
        records {
          id
          title
          ... on ExampleQuestion {
            ${fieldFragment}
          }
        }
      }
    }
  `
}

// Define the shape of the data property for the useQuery hook.
interface IExampleQuestionsSearchData {
  examples: ExampleQuestionsSearchResults
}

/**
 * Function to build the question search query and return a hook used for question searches. The built question search query is
 * curried to the returned hook.
 *
 * ```
 * const fields = `
 *   title
 *   language
 *   user {
 *     name
 *     organizationOwner
 *   }
 * `
 * const useQuestions = makeUseExamples(fields)
 * ...
 * const { questions, status, refetch, pageInfo } = useQuestions({ ...filterObj... })
 * ```
 *
 * @param fieldFragment string representing the fields to select on the Question.
 */
export function makeUseExamples(fieldFragment: string) {
  const qry = buildQuestionQueryLiteral(fieldFragment)

  /**
   * Custom hook to search for questions. Accepts object of search args for the query.
   *
   * @param searchParams Question search params. Null search params will be removed from the query.
   */
  return function useQuestionSearch(
    searchParams: QueryExampleQuestionsSearchArgs,
    options?: QueryHookOptions
  ) {
    // Filter out null search filters.
    const validFilters = useMemo(() => {
      return Object.keys(searchParams).reduce<QueryExampleQuestionsSearchArgs>((acc, argName) => {
        if (searchParams[argName] !== null) {
          acc[argName] = searchParams[argName]
        }
        return acc
      }, {})
    }, [searchParams])

    // Do the query.
    const { data, loading, error, refetch } = useQuery<IExampleQuestionsSearchData>(qry, {
      variables: validFilters,
      fetchPolicy: 'cache-and-network',
      context: {
        source: 'useExampleSearch.ts',
      },
      ...options,
    })

    const questions = data?.examples?.records || []

    let status = queryStates.initial()
    if (loading) {
      status = queryStates.loading()
    } else if (error) {
      status = queryStates.error('Error while loading examples.')
    } else {
      status = queryStates.success()
    }

    const pageInfo: IPageInfo = {
      pageIdx: validFilters.page || 0,
      filteredPageCount: data?.examples?.pagesTotal || 0,
      filteredRecordCount: data?.examples?.totalRecords || 0,
      pageSize: validFilters.perPage || 0,
    }

    return {
      status,
      questions,
      pageInfo,
      refetch,
    }
  }
}

export const memoizedMakeUseExamples = memoize(makeUseExamples)
