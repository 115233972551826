import { gql, useQuery } from '@apollo/client'
import { memoize } from 'lodash'
import { useMemo } from 'react'

import * as queryStates from '../../../queryStates'
import { QueryQuestionsSearchArgs, QuestionsSearchResults } from '../../../types'

export function buildQuestionQueryLiteral(fieldFragment: string) {
  return gql`
    query Questions(
      $page: Int
      $perPage: Int
      $text: String
      $language: String
      $takeHome: Boolean
      $padTypes: [QuestionPadTypes!]
      $testCasesEnabled: Boolean
      $userOnly: Boolean
      $isDraft: Boolean
      $organizationId: Int
      $sort: [QuestionSort!]
    ) {
      questionsSearch(
        page: $page
        perPage: $perPage
        text: $text
        language: $language
        takeHome: $takeHome
        padTypes: $padTypes
        testCasesEnabled: $testCasesEnabled
        userOnly: $userOnly
        isDraft: $isDraft
        organizationId: $organizationId
        sort: $sort
      ) {
        totalRecords
        pagesTotal
        records {
          id
          title
          ... on Question {
            ${fieldFragment}
          }
        }
      }
    }
  `
}

// Define the shape of the data property for the useQuery hook.
interface IQuestionsSearchData {
  questionsSearch: QuestionsSearchResults
}

/**
 * Function to build the question search query and return a hook used for question searches. The built question search query is
 * curried to the returned hook.
 *
 * ```
 * const fields = `
 *   title
 *   language
 *   user {
 *     name
 *     organizationOwner
 *   }
 * `
 * const useQuestions = useMakeUseQuestions(fields)
 * ...
 * const { questions, status, refetch, pageInfo } = useQuestions({ ...filterObj... })
 * ```
 *
 * @param fieldFragment string representing the fields to select on the Question.
 */
export function useMakeUseQuestions(fieldFragment: string) {
  const qry = buildQuestionQueryLiteral(fieldFragment)

  /**
   * Custom hook to search for questions. Accepts object of search args for the query.
   *
   * @param searchParams Question search params. Null search params will be removed from the query.
   */
  return function useQuestionSearch(searchParams: QueryQuestionsSearchArgs) {
    // Filter out null search filters.
    const validFilters = useMemo(() => {
      return Object.keys(searchParams).reduce<QueryQuestionsSearchArgs>((acc, argName) => {
        if (searchParams[argName] !== null) {
          acc[argName] = searchParams[argName]
        }
        return acc
      }, {})
    }, [searchParams])

    // Do the query.
    const { data, loading, error, refetch } = useQuery<IQuestionsSearchData>(qry, {
      variables: validFilters,
      fetchPolicy: 'network-only',
      context: {
        source: 'useQuestionSearch.ts',
      },
    })

    const questions = data?.questionsSearch?.records || []

    let status = queryStates.initial()
    if (loading) {
      status = queryStates.loading()
    } else if (error) {
      status = queryStates.error('Error while loading questions.')
    } else {
      status = queryStates.success()
    }

    const pageInfo: IPageInfo = {
      pageIdx: validFilters.page || 0,
      filteredPageCount: data?.questionsSearch?.pagesTotal || 0,
      filteredRecordCount: data?.questionsSearch?.totalRecords || 0,
      pageSize: validFilters.perPage || 0,
    }

    return {
      status,
      questions,
      pageInfo,
      refetch,
    }
  }
}

export const memoizedMakeUseQuestions = memoize(useMakeUseQuestions)
