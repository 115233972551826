import _FirebaseSyncHandle from './firebase_sync_handle'

let handle: _FirebaseSyncHandle

// Wrapper that returns a singleton instance of _FirebaseSyncHandle.
//
// (There used to be another type of sync handle, OTDSyncHandle, and this
// abstracted over the two, but we've removed OTD for the time being.)
export default function SyncHandle() {
  if (handle == null) throw new Error('sync handle not initialized')

  return handle
}

SyncHandle.init = function (userId: string) {
  if (handle != null) {
    console.error('sync handle already initialized')
    return handle
  }
  return (handle = new _FirebaseSyncHandle(userId))
}

SyncHandle.findOrInit = function (userId: string) {
  return handle ?? SyncHandle.init(userId)
}
