export enum DirectoryItemNameValidityType {
  Valid = 'Valid',
  Empty = 'Empty',
  StartsWithSlash = 'StartsWithSlash',
  EndsWithSlash = 'EndsWithSlash',
  TooLong = 'TooLong',
  InvalidCharacters = 'InvalidCharacters',
  LeadingTrailingWhitespace = 'LeadingTrailingWhitespace',
  Invalid = 'Invalid',
}

export enum DirectoryItemNameValidityLevel {
  Ok = 'Ok',
  Warn = 'Warn',
  Error = 'Error',
}

export interface DirectoryItemNameValidity {
  type: DirectoryItemNameValidityType
  level: DirectoryItemNameValidityLevel
  label: string
}

const relativePathRegex = /(\/|^)\.{1,2}(\/|$)/

export function getDirectoryItemNameValidity(name: string): DirectoryItemNameValidity {
  let type: DirectoryItemNameValidityType
  if (name === '') {
    type = DirectoryItemNameValidityType.Empty
  } else if (name.startsWith('/')) {
    type = DirectoryItemNameValidityType.StartsWithSlash
  } else if (name.endsWith('/')) {
    type = DirectoryItemNameValidityType.EndsWithSlash
  } else if (name.split('/').some((item) => item.length > 255)) {
    // Maximum value for linux based systems
    type = DirectoryItemNameValidityType.TooLong
  } else if (new Blob([name]).size !== name.length) {
    // Check that each character is stored on 1 byte to match linux behavior
    type = DirectoryItemNameValidityType.InvalidCharacters
  } else if (relativePathRegex.test(name)) {
    // Do not allow relative path like something/.., ./something etc
    type = DirectoryItemNameValidityType.Invalid
  } else if (name.trim() !== name) {
    type = DirectoryItemNameValidityType.LeadingTrailingWhitespace
  } else {
    type = DirectoryItemNameValidityType.Valid
  }
  return {
    type,
    ...directoryItemValidities[type],
  }
}

const directoryItemValidities: Record<
  DirectoryItemNameValidityType,
  Omit<DirectoryItemNameValidity, 'type'>
> = {
  [DirectoryItemNameValidityType.Empty]: {
    label: 'A file or folder name must be provided',
    level: DirectoryItemNameValidityLevel.Error,
  },
  [DirectoryItemNameValidityType.StartsWithSlash]: {
    label: 'A file or folder name cannot start with a slash',
    level: DirectoryItemNameValidityLevel.Error,
  },
  [DirectoryItemNameValidityType.EndsWithSlash]: {
    label: 'A file or folder name cannot end with a slash',
    level: DirectoryItemNameValidityLevel.Error,
  },
  [DirectoryItemNameValidityType.TooLong]: {
    label: 'A file or folder name cannot exceed 255 characters',
    level: DirectoryItemNameValidityLevel.Error,
  },
  [DirectoryItemNameValidityType.InvalidCharacters]: {
    label: 'A file or folder name can only contain "1 byte" characters',
    level: DirectoryItemNameValidityLevel.Error,
  },
  [DirectoryItemNameValidityType.Invalid]: {
    label: 'This is not a valid file or folder name',
    level: DirectoryItemNameValidityLevel.Error,
  },
  [DirectoryItemNameValidityType.LeadingTrailingWhitespace]: {
    label: 'Leading or trailing whitespace detected in file or folder name',
    level: DirectoryItemNameValidityLevel.Warn,
  },
  [DirectoryItemNameValidityType.Valid]: {
    label: 'This is a valid file or folder name',
    level: DirectoryItemNameValidityLevel.Ok,
  },
}
