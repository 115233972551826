import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles((theme) => {
  return {
    headerWrap: {
      fontSize: '1rem',
    },
    header: {
      background: '#fff',
      borderBottom: '1px solid #eaeaeb',
      color: theme.palette.text.primary,
      fontSize: '1rem',
    },
    headerLogoLink: {
      display: 'flex',
    },

    headerActionMenuItem: {
      minWidth: 174,
      color: '#353C4E',
    },
    headerActionMenuItemLogout: {
      padding: 0,
    },
    headerActionMenuItemLogoutBtn: {
      letterSpacing: 0,
      padding: '6px 16px',
      justifyContent: 'flex-start',
    },
    headerActionMenuOpener: {
      cursor: 'pointer',
      fontSize: '0.8125rem',
      fontWeight: 500,
    },
    headerActionMenuIcon: {
      height: 24,
      width: 24,
      marginLeft: theme.spacing(0.5),
      '&.flip': {
        transform: 'scaleY(-1)',
      },
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
      }),
    },
    upgradeActionMenuItem: {
      color: '#fff',
      backgroundColor: theme.palette.activation?.main,
      '&:hover': {
        backgroundColor: theme.palette.activation?.dark,
      },
    },
  }
})
