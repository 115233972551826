import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import * as queryStates from '../../../queryStates'

export function buildUserInfoQuery(fragment: string) {
  return gql`
    query UserInfo {
      user {
        id
        ... on User {
          ${fragment}
        }
      }
    }
  `
}

export function useUserInfoHookBuilder<T>(fragment: string) {
  return useMemo(() => {
    const query = buildUserInfoQuery(fragment)
    return function useUserInfo() {
      const { data, error, loading } = useQuery<{ user: T & { id: number } }>(query, {
        context: {
          source: 'useUserInfo.ts',
        },
      })
      const userInfo = data?.user

      let status = queryStates.initial()
      if (loading) {
        status = queryStates.loading()
      } else if (error != null) {
        status = queryStates.error(
          `An error occurred while fetching your user information${
            error.message ? ': ' + error.message : ''
          }`
        )
      } else {
        status = queryStates.success()
      }

      return useMemo(
        () => ({
          userInfo,
          status,
        }),
        [status, userInfo]
      )
    }
  }, [fragment])
}
