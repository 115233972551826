import { CandidateInstructions } from 'graphql/types'
import { useCallback, useEffect, useMemo, useState } from 'react'

import useStateEffect from '../../../../../utils/hooks/useStateEffect'
import { useActiveEnvironment } from '../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import SyncHandle from '../../../sync_handle'

export function useCandidateInstructions(candidateInstructions: CandidateInstructions[] = []) {
  const defaultExpandedArray = useMemo(() => [true, ...candidateInstructions.map(() => false)], [
    candidateInstructions,
  ])

  const [expanded, setExpanded] = useState(defaultExpandedArray)
  const { environment } = useActiveEnvironment()

  useEffect(() => {
    setExpanded(defaultExpandedArray)
  }, [defaultExpandedArray])

  const handleExpand = useCallback(
    (index) => {
      const newExpanded = expanded.map((isExpanded, _index) =>
        _index === index ? !isExpanded : false
      )
      // ensure there's always one step expanded
      if (newExpanded.every((visible) => !visible)) {
        newExpanded[0] = true
      }
      setExpanded(newExpanded)
    },
    [expanded]
  )

  const emptyVisibilitySettings: boolean[] = []
  const candidateVisibilitySettings: boolean[] = useStateEffect(
    (setCandidateVisibilitySettings) => {
      if (environment == null) {
        return
      }

      const settingsWatcher = SyncHandle().watch(
        `environmentCandidateInstructions/${environment.slug}`,
        (settings: boolean[]) => {
          if (settings != null) {
            setCandidateVisibilitySettings(settings)
          }
        }
      )

      return () => {
        SyncHandle().off(`environmentCandidateInstructions/${environment.slug}`, settingsWatcher)
      }
    },
    [environment],
    emptyVisibilitySettings
  )

  return {
    candidateVisibilitySettings,
    expanded,
    handleExpand,
  }
}
