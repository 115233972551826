import { useScreenOrganizationConfig } from 'graphql/hooks/organization/useScreenOrganizationConfig/useScreenOrganizationConfig'
import { useCurrentUser } from 'graphql/hooks/users/useCurrentUser/useCurrentUser'
import { Application } from 'graphql/types'

export function useAppManagingUsers(): Application {
  const { currentUser } = useCurrentUser()
  const { screenOrganizationConfig } = useScreenOrganizationConfig()
  const isCodingameXSell = currentUser?.activeQuota?.planObj?.codingameXsellPlan ?? false
  return (
    screenOrganizationConfig?.appManagingUsers ??
    (isCodingameXSell ? Application.Screen : Application.Interview)
  )
}
