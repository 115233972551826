import { WhiteboardEngine } from '@codinpad/wbengine-client'
import { Box, Theme, useTheme } from '@mui/material'
import * as Sentry from '@sentry/browser'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { GenericErrorView } from '../GenericErrorView/GenericErrorView'
import { useResizeHandler } from './useResizeHandler'
import { initWBEngine, IWhiteboardProps, MessagedError } from './util'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const WhiteboardPreviewMode: React.FC<IWhiteboardProps> = ({
  drawingBoardId,
  darkColorScheme = false,
}) => {
  const theme = useTheme()
  const [wbEngine, setWBEngine] = useState<WhiteboardEngine | null>(null)
  const canvasEl = useRef(null)
  const containerEl = useRef<HTMLDivElement>(null)
  const [isConnected, setIsConnected] = useState(false)
  const [isConnecting, setIsConnecting] = useState(false)
  const authorId = 'whiteboard-preview-mode'

  const drawingTheme = useMemo(() => theme.palette.drawing[darkColorScheme ? 'dark' : 'light'], [
    theme,
    darkColorScheme,
  ])

  // Effect to create an instance of WBEngine.
  const setupWBEngine = useCallback(() => {
    if (wbEngine) return wbEngine
    if (!canvasEl.current) return null

    const wb = initWBEngine({
      sandbox: false,
      canvasElement: canvasEl.current,
      setIsConnected,
    })

    // 'pan' keeps the board in a 'preview' state and from being editable
    wb.setMode('pan')
    // zoomToFit will place the entire drawing into view once objects are initially added
    wb.on('objects:added', async () => {
      await wb.zoomToFit()
    })

    return wb
  }, [wbEngine])

  // Handler + effect to resize the WBEngine canvas when the window size changes.
  useResizeHandler(wbEngine)

  // Callback to connect to the Drawing backend.
  const connect = useCallback(async () => {
    if (isConnecting || isConnected) return

    const wbEngine = setupWBEngine()
    setWBEngine(wbEngine)
    setIsConnecting(true)

    try {
      // no need to pass userData when in preview mode for wbEngine
      await wbEngine?.connect(drawingBoardId, authorId, {})
    } catch (error: unknown) {
      setIsConnected(false)
      Sentry.captureMessage(
        `Drawing initial connection failure: ${(error as MessagedError).message}`,
        {
          level: 'error' as Sentry.SeverityLevel,
          tags: { layer: 'react', feature: 'drawing_mode' },
          extra: {
            error,
          },
        }
      )
    } finally {
      setIsConnecting(false)
    }
  }, [setupWBEngine, isConnecting, isConnected, authorId, drawingBoardId])

  // Effect to trigger the initial connection to the Drawing service.
  useEffect(() => {
    connect()
  }, [connect])

  return (
    <Box ref={containerEl} tabIndex={-1} sx={{ height: '100%', background: drawingTheme.bg }}>
      <ErrorBoundary fallback={(e) => <GenericErrorView error={e} />}>
        <Box height="100%" sx={{ userSelect: 'none' }}>
          <canvas ref={canvasEl}></canvas>
        </Box>
      </ErrorBoundary>
    </Box>
  )
}
