import { Alert, Box, Button, Snackbar, styled, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'

interface IConnectionStatusProps {
  isConnected?: boolean
  isConnecting?: boolean
  handleConnectionRetry: () => void
}

const StatusMessage = styled(Alert)(({ theme, severity }) => ({
  backgroundColor: severity === 'info' ? theme.palette.success.light : theme.palette.error.light,
  color:
    severity === 'info' ? theme.palette.success.contrastText : theme.palette.error.contrastText,
  '.MuiAlert-message': {
    padding: 0,
  },
}))

export const ConnectionStatus: FC<IConnectionStatusProps> = ({
  isConnected,
  isConnecting,
  handleConnectionRetry,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [hideDuration, setHideDuration] = useState<number>()

  useEffect(() => {
    if (isConnected) {
      setHideDuration(2000)
    } else if (isConnecting) {
      setHideDuration(undefined)
      setIsVisible(true)
    }
  }, [isConnected, isConnecting])

  const onClose = () => {
    if (isConnected) {
      setIsVisible(false)
    }
  }

  const RenderConnectingMessage = () => {
    if (isConnecting) {
      return (
        <>
          <Typography>Connecting...</Typography>
        </>
      )
    } else {
      return isConnected ? (
        <Typography>Connected</Typography>
      ) : (
        <>
          <Typography>Not connected </Typography>
          <Button variant="text" onClick={handleConnectionRetry} sx={{ padding: 0 }}>
            Retry
          </Button>
        </>
      )
    }
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isVisible}
      onClose={onClose}
      autoHideDuration={hideDuration}
      sx={{
        position: 'absolute',
      }}
    >
      <StatusMessage severity={isConnecting || isConnected ? 'info' : 'error'} icon={false}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 1,
            alignItems: 'center',
            fontSize: '1rem',
          }}
        >
          {RenderConnectingMessage()}
        </Box>
      </StatusMessage>
    </Snackbar>
  )
}
