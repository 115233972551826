import { gql, useQuery } from '@apollo/client'

import * as queryStates from '../../../queryStates'
import { ScreenOrganizationConfig } from '../../../types'

export const SCREEN_ORGANIZATION_CONFIG_QUERY = gql`
  query ScreenOrganizationConfig {
    screenOrganizationConfig {
      legacySsoEnabled
      legacySsoEnforced
      appManagingUsers
    }
  }
`

interface IScreenOrganizationConfigData {
  screenOrganizationConfig: ScreenOrganizationConfig
}

export function useScreenOrganizationConfig() {
  const {
    data,
    error,
    loading,
    refetch,
    startPolling,
    stopPolling,
  } = useQuery<IScreenOrganizationConfigData>(SCREEN_ORGANIZATION_CONFIG_QUERY, {
    context: {
      source: 'useScreenOrganizationConfig/useScreenOrganizationConfig.ts',
    },
  })

  const screenOrganizationConfig = data?.screenOrganizationConfig

  let status = queryStates.initial()
  if (loading) {
    status = queryStates.loading()
  } else if (error) {
    status = queryStates.error(
      `An error occurred while fetching the screen organization config ${
        error.message ? ': ' + error.message : ''
      }`
    )
  } else {
    status = queryStates.success()
  }

  return {
    screenOrganizationConfig,
    status,
    refetch,
    startPolling,
    stopPolling,
  }
}
