import styled from '@emotion/styled'
import React from 'react'

const StyledIframe = styled('iframe')({
  width: '100%',
  height: '100%',
  border: 0,
})

export const GSheetsIframe: React.FC<{ spreadsheetId: string | undefined; preview?: boolean }> = ({
  spreadsheetId,
  preview = false,
}) => {
  if (spreadsheetId == null) {
    return null
  }

  return (
    <StyledIframe
      src={`https://docs.google.com/spreadsheets/d/${spreadsheetId}${
        preview ? '/preview' : '?rm=embedded'
      }`}
    />
  )
}
