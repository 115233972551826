import { useDraggable } from '@dnd-kit/core'
import { Box, Menu, MenuItem, styled, Typography } from '@mui/material'
import clsx from 'classnames'
import { dirname } from 'path-browserify'
import React, { useCallback, useState } from 'react'
import { FileWithPath } from 'react-dropzone'

import { ReactComponent as AddFileIcon } from '../../../../../../assets/images/projects/addFile.svg'
import { ReactComponent as AddFolderIcon } from '../../../../../../assets/images/projects/addFolder.svg'
import { ReactComponent as ExpanderIcon } from '../../../../../../assets/images/projects/expander.svg'
import { ReactComponent as FolderIcon } from '../../../../../../assets/images/projects/folder.svg'
import { usePadConfigValue } from '../../../../dashboard/components/PadContext/PadContext'
import { useActiveEnvironment } from '../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { ConfirmDeleteDialog } from '../ConfirmDeleteDialog'
import { FolderDropZone } from '../FolderDropZone'
import { DroppableData } from '../FolderDropZone/types'
import { useContextMenu } from '../useContextMenu/useContextMenu'
import { WrappedToDepth } from '../utils/WrappedToDepth'
import { RenameFolder } from './RenameFolder'

const TopLine = styled(Box)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.filePane?.highlightBackground,
  },
}))

const Expander = styled(ExpanderIcon)(({ theme }) => ({
  '& path': {
    fill: theme.palette.mode === 'dark' ? 'white' : '#828282',
  },
}))

interface IFolder {
  children: React.ReactNode
  depth: number
  name: string
  path: string
  defaultOpen?: boolean
  onAddFileToFolder?: (folderPath: string) => void
  onAddFolderToFolder?: (folderPath: string) => void
  onDeleteFolder?: (folderPath: string) => void
  showHighlight?: boolean
  showSubHighlights?: boolean
  onFileUpload?: (files: FileWithPath[], destination: string) => void
}

export const Folder = ({
  children,
  depth,
  name,
  path,
  defaultOpen = false,
  onAddFileToFolder,
  onAddFolderToFolder,
  onDeleteFolder,
  showHighlight = false,
  showSubHighlights = false,
  onFileUpload,
}: IFolder) => {
  const { activeFile, environment } = useActiveEnvironment()
  const {
    contextMenu,
    handleContextMenu,
    handleContextMenuClose,
    setContextMenu,
  } = useContextMenu()
  const [open, setOpen] = useState(defaultOpen)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const isPlayback = usePadConfigValue('isPlayback')
  const [isRenaming, setIsRenaming] = useState(false)

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: path,
    data: {
      type: 'folder',
      path,
    } as DroppableData,
  })

  const toggleOpen = () => {
    setOpen(!open)
  }

  const handleHeaderAddFolderClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      setOpen(true)
      onAddFolderToFolder?.(path)
    },
    [onAddFolderToFolder, path]
  )
  const handleHeaderAddFileClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      setOpen(true)
      if (onAddFileToFolder != null) {
        onAddFileToFolder(path)
      }
    },
    [onAddFileToFolder, path]
  )
  const handleContextMenuRenameFolderClick = useCallback(() => {
    setContextMenu(null)
    setOpen(true)
    setIsRenaming(true)
  }, [setContextMenu])
  const handleContextMenuAddFolderClick = useCallback(() => {
    setContextMenu(null)
    setOpen(true)
    onAddFolderToFolder?.(path)
  }, [onAddFolderToFolder, path, setContextMenu])
  const handleContextMenuAddFileClick = useCallback(() => {
    setContextMenu(null)
    setOpen(true)
    onAddFileToFolder?.(path)
  }, [onAddFileToFolder, path, setContextMenu])
  const handleContextMenuDeleteFolderClick = useCallback(() => {
    setContextMenu(null)
    setConfirmDialogOpen(true)
  }, [setContextMenu])

  const activeFilePath = activeFile == null ? '' : activeFile.path.replace(activeFile.name, '')
  const hasActiveFile = activeFilePath === path
  const highlightDepth = activeFilePath.split('/').length - 1

  if (isRenaming) {
    return (
      <RenameFolder
        folderPath={dirname(path)}
        depth={depth}
        oldFolderName={name}
        onExit={() => setIsRenaming(false)}
        showHighlight={showHighlight}
        showSubHighlights={showSubHighlights}
      />
    )
  }

  return (
    <FolderDropZone path={path} onOpen={() => setOpen(true)} onFileUpload={onFileUpload}>
      <div ref={setNodeRef} {...listeners} {...attributes}>
        <div className={clsx('folder', { open, hasActiveFile })}>
          <TopLine
            className="top-line"
            onClick={toggleOpen}
            onContextMenu={environment?.projectTemplateSlug != null ? handleContextMenu : () => {}}
          >
            <WrappedToDepth
              depth={depth}
              highlightDepth={highlightDepth}
              showHighlight={showHighlight}
              showSubHighlights={showSubHighlights}
            >
              <div className="folder-wrapper">
                <Expander className="expander" />
                <FolderIcon className="icon" />
                <div className="name">
                  <Typography variant="body1" fontSize="inherit" color="inherit">
                    {name}
                  </Typography>
                </div>
                {!isPlayback && environment?.projectTemplateSlug != null && (
                  <div className="icons">
                    <AddFolderIcon onClick={handleHeaderAddFolderClick} />
                    <AddFileIcon onClick={handleHeaderAddFileClick} />
                  </div>
                )}
              </div>
            </WrappedToDepth>
          </TopLine>
          <Menu
            open={contextMenu != null}
            onClose={handleContextMenuClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            <MenuItem dense onClick={handleContextMenuAddFileClick}>
              Add File
            </MenuItem>
            <MenuItem dense onClick={handleContextMenuAddFolderClick}>
              Add Folder
            </MenuItem>
            <MenuItem dense onClick={handleContextMenuRenameFolderClick}>
              Rename Folder
            </MenuItem>
            <MenuItem dense onClick={handleContextMenuDeleteFolderClick}>
              Delete Folder
            </MenuItem>
          </Menu>
          <div className="contents">{children}</div>
        </div>
        <ConfirmDeleteDialog
          fileName={name}
          open={confirmDialogOpen}
          onCancel={() => setConfirmDialogOpen(false)}
          onConfirm={() => (onDeleteFolder != null ? onDeleteFolder(path) : null)}
        />
      </div>
    </FolderDropZone>
  )
}
