import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import React from 'react'

interface IConfirmDeleteDialogProps {
  fileName: string
  open: boolean
  onCancel: () => void
  onConfirm: () => void
}
export const ConfirmDeleteDialog: React.FC<IConfirmDeleteDialogProps> = ({
  fileName,
  open,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        Are you sure you want to delete "{fileName}"?
        <br />
        This action cannot be undone.
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Delete</Button>
      </DialogActions>
    </Dialog>
  )
}
