import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Box, List, ListItemButton, ListItemText } from '@mui/material'
import { ScrollView } from 'packs/main/ScrollView/ScrollView'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import { ToolbarsContext } from '../../Whiteboard'
import { ToolControlItem } from './ToolControlItem'

const FONTS = [
  'Arial',
  'Verdana',
  'Tahoma',
  'Trebuchet MS',
  'Times New Roman',
  'Georgia',
  'Garamond',
  'Courier New',
  'Brush Script MT',
  'Comic Sans MS',
  'Luminari',
]

export const FontControl: FC = () => {
  const { wbEngine, drawingTheme, setDefaultFontFamily } = useContext(ToolbarsContext)
  const [currentFont, setCurrentFont] = useState('Arial')

  useEffect(() => {
    const objectFont = wbEngine?.selectedObjects[0]?.fontFamily
    if (!objectFont || objectFont === 'sans-serif') {
      setCurrentFont('Arial')
    } else {
      setCurrentFont(objectFont)
    }
  }, [wbEngine?.selectedObjects])

  const handleFontSelection = useCallback(
    (font: string) => {
      wbEngine!.setOnSelection({ fontFamily: font })
      setDefaultFontFamily(font)
    },
    [setDefaultFontFamily, wbEngine]
  )

  if (!wbEngine) return null

  return (
    <>
      <ToolControlItem
        tooltip="Font"
        sx={{ width: '120px', justifyContent: 'flex-start', fontFamily: currentFont }}
        popperMenu={({ open, setOpen }) => (
          <ScrollView sx={{ maxHeight: '200px', overflowY: 'scroll' }}>
            <List sx={{ color: drawingTheme.toolbarColor }} dense>
              {FONTS.map((font) => (
                <ListItemButton
                  key={font}
                  onClick={() => {
                    handleFontSelection(font)
                    setOpen(false)
                  }}
                >
                  <ListItemText sx={{ '.MuiTypography-root': { fontFamily: font } }}>
                    {font}
                  </ListItemText>
                </ListItemButton>
              ))}
            </List>
          </ScrollView>
        )}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {currentFont}
          <ArrowDropDownIcon />
        </Box>
      </ToolControlItem>
    </>
  )
}
