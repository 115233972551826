import { gql, useQuery } from '@apollo/client'
import { Language, Question } from 'graphql/types'
import { LanguageDisplay } from 'packs/dashboard/components/LanguageDisplay/LanguageDisplay'
import React, { useMemo } from 'react'

export const LANGUAGE_SELECT_QUERY = gql`
  query LanguageSelect {
    languages {
      id
      name
    }
  }
`

export const QuestionLanguageDisplay: React.FC<{ question: Question }> = ({ question }) => {
  const { data: langs } = useQuery(LANGUAGE_SELECT_QUERY)

  const langDisplay = useMemo(() => {
    return question.spreadsheet != null
      ? 'Google Sheets'
      : question.projectTemplate == null
      ? (langs?.languages ?? []).find((o: Language) => o.id === question.language!)?.name
      : question.projectTemplate.name
  }, [langs?.languages, question.language, question.projectTemplate, question.spreadsheet])

  return (
    <LanguageDisplay
      display={langDisplay}
      language={
        question.spreadsheet != null
          ? 'gsheets'
          : question.projectTemplate == null
          ? question.language!
          : question.projectTemplate.slug
      }
    />
  )
}
