import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import * as queryStates from '../../../queryStates'
import { Organization } from '../../../types'

export function buildOrganizationQuery(fields: string) {
  // NOTE: If attempting to select any fields from `joinableDomains`, , `domain` and `id` MUST BE IN THE SELECTION
  // on the `JoinableOrganizationDomain` type. Otherwise Apollo will throw a fit and report an error, despite the
  // query or mutation actually succeeding.
  return gql`
    query OrganizationSettings {
      organization {
        id
        name
        ... on Organization {
          ${fields}
        }
      }
    }
  `
}

export interface IUseOrganizationReturn {
  organization: Organization | null
  status: queryStates.QueryState
  refetch: () => void
}

export interface IOrganizationData {
  organization: Organization
}

export function useMemoizedOrganizationHookMaker(fieldFragment: string) {
  return useMemo(() => makeUseOrganizationHook(fieldFragment), [fieldFragment])
}

/**
 * Custom hook to fetch information about the authenticated user's Organization.
 *
 * Note that this hook is safe to call in multiple places without fear of triggering numerous identical queries. The
 * default fetch policy for Apollo client is being used, which is "cache-first", so once the first query is cached,
 * subsequent queries for the Organization will hit the cache instead of the network.
 */
export function makeUseOrganizationHook(fields: string) {
  const query = buildOrganizationQuery(fields)

  return function useOrganization() {
    const { data, loading, error, refetch } = useQuery<IOrganizationData>(query, {
      context: { source: 'useOrganization.ts' },
    })

    const organization = data?.organization

    let status = queryStates.initial()
    if (loading) {
      status = queryStates.loading()
    } else if (error !== undefined) {
      status = queryStates.error(
        'An error occurred while retrieving your organization information.'
      )
    } else if (organization !== undefined) {
      // Note that `null` is an acceptable value for the organization. The backend specifies `null` if the user is not
      // a member of an organization.
      status = queryStates.success()
    }

    return {
      organization,
      status,
      refetch,
    }
  }
}
