import { TabContext } from '@mui/lab'
import React, { createContext } from 'react'

import { TabSizes } from './types'

interface TabsContextContract {
  size: TabSizes
  value: string
}
export const TabsContext = createContext<TabsContextContract>({
  size: 'medium',
  value: '',
})

export const TabsContextProvider: React.FC<{ value: TabsContextContract }> = ({
  children,
  value,
}) => {
  return (
    <TabsContext.Provider value={value}>
      <TabContext value={value.value}>{children}</TabContext>
    </TabsContext.Provider>
  )
}
