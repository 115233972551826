import clsx from 'classnames'
import { TestCase } from 'graphql/types'
import React from 'react'

type LegacyTestCase = Omit<TestCase, 'returnValue'> & {
  return_value: string
}

interface IQuestionSelectedTestCasesProps {
  testCases: Array<LegacyTestCase>
  className?: string
}

export const QuestionSelectedTestCases: React.FC<IQuestionSelectedTestCasesProps> = function ({
  testCases,
  className = '',
}) {
  const testCasesTotal = testCases ? testCases.length : 0
  const testCasesVisible = testCases.filter((o) => o.visible).length

  return (
    <div className={clsx('QuestionSelected-testCases', className)}>
      <p className="QuestionSelected-testCasesSummary">
        {testCasesTotal} test case{testCasesTotal === 1 ? '' : 's'} ({testCasesVisible} visible,{' '}
        {testCasesTotal - testCasesVisible} hidden) {testCasesTotal === 1 ? 'is' : 'are'} included
        in this question.
      </p>
      <table className="QuestionSelected-testCasesTable table">
        <thead>
          <tr>
            <th>Visible</th>
            <th>Arguments</th>
            <th>Expected return value</th>
          </tr>
        </thead>
        <tbody>
          {testCases.map((tc, index) => (
            <tr key={`${tc.id}/${index}`} className="QuestionSelected-testCaseDetail">
              <td>{tc.visible ? '✓' : ''}</td>
              <td>
                <pre>
                  {typeof tc.arguments === 'object' ? tc.arguments?.join(', ') : tc.arguments}
                </pre>
              </td>
              <td>
                <pre>{tc.return_value}</pre>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default QuestionSelectedTestCases
