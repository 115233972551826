import '../../../../main/Monaco/MonacoThemes'

import Monaco, { useThemeColor } from '@codingame/monaco-editor-react'
import { monaco } from '@codingame/monaco-editor-wrapper'
import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import React, { useMemo, useState } from 'react'

import { fileIdToPath } from '../../../../../utils/multifile'
import { Folder } from '../../../../main/Monaco/FilePane/FileTree/Folder'
import {
  DirectoryItem,
  DirectoryItemType,
} from '../../../../main/Monaco/FilePane/useDirectory/useDirectory'
import { buildDirectory } from '../../../../main/Monaco/FilePane/utils/buildDirectory'
import { MonacoFile } from '../../../../main/Monaco/FilePane/utils/types'
import { FileLine } from './FileLine'
import type { ITabbedContentProps } from './Tabs'

interface ProjectFilesProps extends ITabbedContentProps {
  /** Including a prefix on the file uri allows us to differentiate a file being loaded in possibly more than one monaco
   * at a time. Case in point: in the pad view, this component is used in the question selection menu to show starter
   * code, but a Monaco is also present for the pad code editor. If a starter code file with the same file path/uri
   * is rendered in the question starter code and in the pad code editor, the code from the starter code leaks into
   *the pad code editor.
   */
  fileUriPrefix?: string
}
export const ProjectFiles: React.FC<ProjectFilesProps> = ({
  question,
  monacoTheme,
  fileUriPrefix,
}) => {
  const fileContents = question.fileContents
  const [currentFile, setCurrentFile] = useState(fileContents[0]?.path)
  const currentFilePath = fileIdToPath(currentFile)
  const currentFileName = currentFilePath.split('/').reverse()[0]
  const currentFileFolder = currentFile == null ? '' : currentFilePath.replace(currentFileName, '')
  const [isHovering, setIsHovering] = useState(false)

  const currentFileMeta = useMemo(
    () => fileContents.find((f: MonacoFile) => f.path === currentFile),
    [currentFile, fileContents]
  )

  const sidebarBackground = useThemeColor('sidebar.background')
  const sidebarForeground = useThemeColor('sidebar.foreground')
  const style = useMemo(
    () => ({
      backgroundColor: sidebarBackground,
      color: sidebarForeground,
    }),
    [sidebarBackground, sidebarForeground]
  )

  if (monacoTheme != null) {
    monaco.editor.setTheme(monacoTheme)
  }

  const directory = useMemo(() => {
    return buildDirectory(
      fileContents.map((file: MonacoFile) => {
        const filePath = fileIdToPath(file.path)
        return {
          fileId: file.path,
          name: filePath,
          path: filePath,
        }
      })
    )
  }, [fileContents])

  const renderDirectoryItem = (item: DirectoryItem, depth: number) => {
    if (item.type === DirectoryItemType.file) {
      const isInActiveFolder =
        currentFileFolder !== '' && item.file!.path.startsWith(currentFileFolder)
      return (
        <FileLine
          key={item.name}
          file={item.file!}
          depth={depth}
          activeFileId={currentFile}
          setActiveFile={setCurrentFile}
          showHighlight={isHovering || isInActiveFolder}
          showSubHighlights={isHovering}
        />
      )
    } else {
      return (
        <Folder
          key={item.name}
          name={item.name}
          path={item.path + '/'}
          defaultOpen={true}
          depth={depth}
        >
          {item.children.map((child) => renderDirectoryItem(child, depth + 1))}
        </Folder>
      )
    }
  }

  if (fileContents.length === 0) {
    return null
  }

  const fileUri = (fileUriPrefix ?? '') + fileIdToPath(currentFile)

  return (
    <Box className="EnvironmentEditor" height="100%">
      <Box display="flex" flexDirection="row" height="100%">
        {fileContents.length > 1 && (
          <Box
            height="100%"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <div className={clsx('file-pane', 'open')} style={style} data-testid="file-pane">
              <section>
                <div className="file-pane-header">
                  <Typography variant="subtitle1">Files</Typography>
                </div>
                <div className="file-pane-contents">
                  {directory.map((item) => {
                    return renderDirectoryItem(item, 0)
                  })}
                </div>
              </section>
            </div>
          </Box>
        )}
        <Box flexGrow={1} height="100%">
          {currentFileMeta?.binary || currentFileMeta?.large ? (
            <Box
              sx={(theme) => {
                return {
                  color: theme.palette.editor.iconStroke,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }
              }}
            >
              <Typography>
                {currentFileMeta?.binary
                  ? 'Unable to display file'
                  : 'File is too large to display'}
              </Typography>
            </Box>
          ) : (
            <Monaco
              value={currentFileMeta?.contents}
              fileUri={fileUri}
              options={{ readOnly: true }}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
