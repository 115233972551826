import {
  CoderPadIcon,
  CodinGameIcon,
  MapIcon,
} from '@codinpad/shared-components/components/icons/index.js'
import { ProductSwitcherItemProps } from '@codinpad/shared-components/components/productSwitcher/ProductSwitcherItem'
import { Theme, useMediaQuery } from '@mui/material'
import { useCurrentUser } from 'graphql/hooks/users/useCurrentUser/useCurrentUser'
import { track } from 'packs/main/coderpad_analytics'
import React, { useEffect, useMemo, useState } from 'react'
import { checkProductSwitcherFlag, checkSkillMappingFlag } from 'utils/checkFeatureFlag'
import { cgBaseUrl, getIsCgUser } from 'utils/codingameService'

interface useProductSwitcherItemsParams {
  forced?: boolean
  isScreenSelected?: boolean
}

export function useProductSwitcherItems(
  useProductSwitcherItemsParams?: useProductSwitcherItemsParams
): ProductSwitcherItemProps<'a'>[] | undefined {
  const { forced, isScreenSelected } = useProductSwitcherItemsParams || {}
  const [isCodinGameCustomer, setIsCodinGameCustomer] = useState<boolean>(true)
  const { currentUser } = useCurrentUser()
  const useProductSwitcher = checkProductSwitcherFlag() || forced
  const codingameItem = useCodingameItem(isCodinGameCustomer)
  const coderpadItem = useCoderpadItem()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      if (!currentUser) {
        return
      }
      setIsCodinGameCustomer(await getIsCgUser())
    })()
  }, [currentUser])

  const items = useMemo(() => {
    if (!useProductSwitcher) {
      return
    }

    const codingameScreenItem = codingameItem[0]
    const codingameOtherItems = codingameItem.slice(1) ?? []
    return [
      {
        ...codingameScreenItem,
        selected: isScreenSelected,
      },
      {
        ...coderpadItem,
        selected: !isScreenSelected,
      },
      ...codingameOtherItems,
    ]
  }, [useProductSwitcher, codingameItem, isScreenSelected, coderpadItem])

  return items
}

export function useCodingameItem(isCodinGameCustomer?: boolean) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const isSkillMappingEnabled = checkSkillMappingFlag()
  return useMemo(() => {
    let items = [
      {
        ...(isMobile
          ? {
              logo: <CodinGameIcon size="small" />,
              logoSelected: <CodinGameIcon size="small" state="selected" />,
            }
          : {
              logo: <CodinGameIcon />,
              logoHovered: <CodinGameIcon state="hovered" />,
              logoSelected: <CodinGameIcon state="selected" />,
            }),
        label: 'SCREEN',
        tooltip: <span>Auto-graded tech assessments</span>,
        tryLabel: isCodinGameCustomer === false ? 'TRY' : undefined,
        href: `${cgBaseUrl}/dashboard/campaigns/`,
        onClick: () =>
          track('product switcher product clicked', {
            product: 'CodinGame',
            'vertical position': 1,
          }),
      },
    ]

    if (isSkillMappingEnabled) {
      items = [
        ...items,
        {
          ...(isMobile
            ? {
                logo: <MapIcon size="small" />,
                logoSelected: <MapIcon size="small" state="selected" />,
              }
            : {
                logo: <MapIcon />,
                logoHovered: <MapIcon state="hovered" />,
                logoSelected: <MapIcon state="selected" />,
              }),
          label: 'MAP',
          tryLabel: 'BETA',
          tooltip: <span>Engage your team and sharpen their skills</span>,
          href:
            isCodinGameCustomer === false
              ? `${cgBaseUrl}/register/`
              : `${cgBaseUrl}/dashboard/map/employees`,
          onClick: () =>
            track('product switcher product clicked', {
              product: 'Skill Mapping',
              'vertical position': 3,
            }),
        },
      ]
    }

    return items
  }, [isCodinGameCustomer, isMobile, isSkillMappingEnabled])
}

export function useCoderpadItem() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  return useMemo(() => {
    return {
      ...(isMobile
        ? {
            logo: <CoderPadIcon size="small" />,
            logoSelected: <CoderPadIcon size="small" state="selected" />,
          }
        : {
            logo: <CoderPadIcon />,
            logoHovered: <CoderPadIcon state="hovered" />,
            logoSelected: <CoderPadIcon state="selected" />,
          }),
      label: 'INTERVIEW',
      tooltip: <span>Collaborative live interview</span>,
      href: '/dashboard/pads',
      onClick: () =>
        track('product switcher product clicked', {
          product: 'CoderPad',
          'vertical position': 2,
        }),
    }
  }, [isMobile])
}
