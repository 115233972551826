import { Link, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { FunctionComponent, SVGProps, useMemo } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'

import { track } from '../../../../main/coderpad_analytics'
import { ReactComponent as DatabasesIcon } from './icons/Databases.svg'
import { ReactComponent as FilesIcon } from './icons/Files.svg'
import { ReactComponent as PadListIcon } from './icons/PadsList.svg'
import { ReactComponent as QuestionBankIcon } from './icons/QuestionBank2.svg'
import { ReactComponent as SandboxIcon } from './icons/Sandbox.svg'
import { ReactComponent as UsersIcon } from './icons/Users.svg'

const useNavItemStyles = makeStyles((theme) => ({
  root: {
    color: 'initial',

    '&.active': {
      color: theme.palette.primary.main,

      '& svg': {
        '& path': {
          stroke: theme.palette.primary.main,
          fill: '#fff',
        },
      },
    },
  },
}))
const useNavTextStyles = makeStyles(() => ({
  root: {
    margin: 0,
  },
  primary: {
    whiteSpace: 'nowrap',
    fontSize: '0.9375rem',
    color: 'inherit',
  },
}))

const useNavIconStyles = makeStyles((theme) => ({
  root: {
    minWidth: 34,
    width: 34,
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}))

interface INavItemProps {
  text: string
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>
  iconProps?: SVGProps<SVGSVGElement>
  destination: string
  isLegacyView?: boolean
  openNewTab?: boolean
  /**
   * react-router would normally take care of adding the `active` class to the nav items. However when rendering
   * the side nav in a Rails view, there is no react-router, so certain nav items will have to designate whether or
   * not they are active.
   */
  isActiveOverride?: boolean
  onClick?: () => void
}

const NavItem: React.FC<INavItemProps> = ({
  text,
  Icon,
  destination,
  isLegacyView,
  iconProps,
  isActiveOverride,
  openNewTab,
  onClick,
}) => {
  const navItemStyles = useNavItemStyles()
  const textStyles = useNavTextStyles()
  const iconStyles = useNavIconStyles()

  const itemProps = useMemo(() => {
    if (isLegacyView) {
      return {
        component: Link,
        href: destination,
      }
    } else {
      return {
        component: RouterLink,
        to: destination,
      }
    }
  }, [isLegacyView, destination])

  if (openNewTab) {
    // @ts-ignore
    itemProps.target = '_blank'
  }

  return (
    <ListItem
      {...itemProps}
      sx={{ color: 'initial' }}
      classes={navItemStyles}
      className={isActiveOverride ? 'active' : ''}
      onClick={onClick}
    >
      <ListItemIcon classes={iconStyles}>
        <Icon {...(iconProps || {})} />
      </ListItemIcon>
      <ListItemText primary={text} classes={textStyles} />
    </ListItem>
  )
}

interface IListItemProps {
  noRouter?: boolean
  hasOrganization?: boolean
}

export const PadList: React.FC<IListItemProps> = ({ noRouter }) => {
  return (
    <NavItem
      text="Pads List"
      Icon={PadListIcon}
      destination="/dashboard/pads"
      isLegacyView={noRouter}
      iconProps={{ height: 23, width: 29 }}
      onClick={() => track('sidenav menu item clicked', { item: 'pads', version: 'original' })}
    />
  )
}

export const QuestionBank: React.FC<IListItemProps> = ({ noRouter }) => {
  return (
    <NavItem
      text="Question Bank"
      Icon={QuestionBankIcon}
      destination="/dashboard/questions"
      isLegacyView={noRouter}
      iconProps={{ height: 26, width: 30 }}
      onClick={() => track('sidenav menu item clicked', { item: 'questions', version: 'original' })}
    />
  )
}

export const Files: React.FC<IListItemProps> = ({ noRouter }) => {
  return (
    <NavItem
      text="Files"
      Icon={FilesIcon}
      destination="/dashboard/files"
      isLegacyView={noRouter}
      iconProps={{ height: 32, width: 24 }}
      onClick={() => track('sidenav menu item clicked', { item: 'files', version: 'original' })}
    />
  )
}

export const Databases: React.FC<IListItemProps> = ({ noRouter }) => {
  return (
    <NavItem
      text="Databases"
      Icon={DatabasesIcon}
      destination="/dashboard/databases"
      isLegacyView={noRouter}
      iconProps={{ height: 28, width: 23 }}
      onClick={() => track('sidenav menu item clicked', { item: 'databases', version: 'original' })}
    />
  )
}

export const Users: React.FC<IListItemProps> = ({ noRouter, hasOrganization }) => {
  return (
    <NavItem
      text="Users"
      Icon={UsersIcon}
      destination={hasOrganization ? '/dashboard/users' : '/organization/new'}
      isLegacyView={noRouter}
      iconProps={{ height: 19, width: 29 }}
      onClick={() => track('sidenav menu item clicked', { item: 'users', version: 'original' })}
    />
  )
}

export const Sandbox: React.FC<IListItemProps> = () => {
  return (
    <NavItem
      text="Sandbox"
      Icon={SandboxIcon}
      destination="/sandbox"
      isLegacyView={true}
      iconProps={{ height: 32, width: 32 }}
      openNewTab={true}
      onClick={() => track('sidenav menu item clicked', { item: 'sandbox', version: 'original' })}
    />
  )
}
